.menu-left {
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  display: flex;

  li {
    list-style: none;
    @include ForMarginPadding(margin, rem(0), rem(44), rem(0), rem(0));

    &:last-child {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    }

    a {
      display: block;
      text-decoration: none;
      position: relative;
      color: $white;
      font-size: rem(16);

      span {
        display: block;
      }

      .dropUpArrow {
        top: calc(100% + 1px);
      }

      i {
        display: none;
      }

      &:hover {
        text-decoration: none;
        color: $primary
      }
    }
  }
}

.menuList {
  // width: 30%;
  @include ForMarginPadding(padding, rem(5), rem(20), rem(5), rem(20));

  ul {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    color: $secondary;
    list-style: none;
    font-size: rem(16);
    font-weight: 400;
    position: relative;
    height: 100%;

    li {
      display: block;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(5), rem(0));

      >a {
        color: $secondary;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        font-size: rem(16);
        @include transitions(0.5s);

        i {
          position: relative;

          &:before {
            font-size: rem(12);
          }
        }
      }

      ul {
        width: 100%;
        display: block;
        position: absolute;
        @include leftToRight(100%);
        top: 0;
        opacity: 0;
        visibility: hidden;
        transform: translateX(rem(-30));
        @include transitions(0.5s);
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(50));

        &:before {
          position: absolute;
          @include leftToRight(rem(25));
          top: 0;
          width: 1px;
          height: 100%;
          background: rgba($black, 0.2);
          content: '';
        }
      }
    }
  }
}

.menuImage {
  width: 38%;
  height: rem(180);
  position: relative;
}

@include for-desktop() {
  .menu-left {
    li {
      &.active {
        .dropUpArrow {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
        }
      }
    }
  }
}

@include for-tablet-landscape() {
  .menu-left {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      .dropdown-wrapper {
        display: none;
      }

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }

    .menuImage {
      width: 100%;
      height: rem(220);
    }
  }

  .menuImage {
    width: 100%;
    height: rem(220);
  }

  .menuList {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
    width: 100%;

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }
}

@include for-tablet-portrait() {
  .menu-left {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      .dropdown-wrapper {
        display: none;
      }

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }

  .menuImage {
    width: 100%;
    height: rem(220);
  }

  .menuList {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
    width: 100%;

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }
}

@include for-phone-landscape() {
  .menu-left {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      .dropdown-wrapper {
        display: none;
      }

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }

  .menuImage {
    width: 100%;
    height: rem(220);
  }

  .menuList {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
    width: 100%;

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }
}

@include for-phone-portrait() {
  .menu-left {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      .dropdown-wrapper {
        display: none;
      }

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }

  .menuImage {
    width: 100%;
    height: rem(220);
  }

  .menuList {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
    width: 100%;

    li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;
          display: block;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }
}
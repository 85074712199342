.dateDropDown {
  background: $white;
  @include transitions(0.5s);
  position: absolute;
  @include RightToLeft(rem(-50));
  top: calc(100% + 20px);
  width: rem(1160);
  @include borderRadius(10px);
  filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
  z-index: 2;
  @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));

  &:before {
    content: '';
    position: absolute;
    @include RightToLeft(rem(80));
    @include ForMarginPadding(margin, auto, auto, auto, auto);
    top: rem(-13);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 13px solid rgba($white, 1);
    @include transitions(0.5s);
  }

  // transition
  &.active-appear-done,
  &.active-appear-active,
  &.active-appear {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 20px);
  }

  &.active-enter,
  &.active-enter-active,
  &.active-enter-done {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }

  &.active-exit,
  &.active-exit-active,
  &.active-exit-done {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 0);
  }
}

.dateHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: solid 1px $closeButton;
  @include ForMarginPadding(padding, rem(0), rem(0), rem(10), rem(0));
  @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
}

.dateBody {
  border-bottom: solid 0.5px $closeButton;
  @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
}

.dateFooter {
  @include ForMarginPadding(padding, rem(10), rem(0), rem(0), rem(0));
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
}

.selectDateHeading {
  width: 50%;
  display: flex;
  align-items: center;

  p {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  i {
    @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
    position: relative;
    top: rem(-2);

    &:before {
      font-size: rem(18);
    }
  }
}

.showBusiness {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.toggleBtn {
  display: flex;

  input[type='checkbox'].switch {
    appearance: none;
    width: rem(32);
    height: rem(16);
    background: $white;
    border: solid 1px $secondary;
    @include borderRadius(10px);
    position: relative;
    cursor: pointer;
    outline: none;
    transition: all 0.2s ease-in-out;
  }

  input[type='checkbox'].switch:checked {
    background: $secondary;
  }

  input[type='checkbox'].switch:after {
    position: absolute;
    content: '';
    width: rem(16);
    height: rem(16);
    @include borderRadius(50%);
    background: $secondary;
    border: solid 1px $secondary;
    box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.3);
    @include leftToRight(0);
    top: rem(-1);
    transition: all 0.2s ease-in-out;
  }

  input[type='checkbox'].switch:checked:after {
    left: rem(16);
    background: $white;
  }

  label {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(15));
  }
}

.singleDatePicker {
  width: 48%;
}

.dateLegends {
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  display: flex;
  column-gap: rem(10);

  li {
    list-style: none;
    display: flex;
    align-items: center;
    column-gap: rem(10);

    i {
      width: rem(16);
      height: rem(16);
      @include borderRadius(50%);
      display: block;
      border-width: 1px;
      border-style: solid;

      &.tealBg {
        border-color: $teal;
        background: $teal;
      }

      &.secondryBg {
        border-color: $secondary;
        background: $secondary;
      }

      &.geryBg {
        border-color: $grey;
        background: $grey;
      }

      &.primaryBg {
        border-color: $primary;
        background: $primary;
      }

      &.whiteBg {
        border-color: $lightGrey;
        background: $white;
      }
    }

    span {
      font-size: rem(16);
      display: block;
    }
  }
}

.singleDatePicker {
  font-family: $fontFamilyBase;
  width: 100%;
  border: none;
  @include borderRadius(0px);
  font-size: rem(16);
}

/*.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range{}*/
/*
.react-datepicker__day--in-selecting-range:not(
.react-datepicker__day--in-range,
 .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
   .react-datepicker__year-text--in-range
   ),
.react-datepicker__month-text--in-selecting-range:not(
.react-datepicker__day--in-range,
 .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
   .react-datepicker__year-text--in-range
   ),
.react-datepicker__quarter-text--in-selecting-range:not(
.react-datepicker__day--in-range,
 .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
   .react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(
.react-datepicker__day--in-range,
 .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--in-range,
   .react-datepicker__year-text--in-range
   )*/


/* Flight Range DatePicker */
.react-google-flight-datepicker {
  .flightRange {
    &.date-picker {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      @include borderRadius(0px);
    }

    .date-picker-input {
      display: none;
    }

    .dialog-footer {
      display: none;
    }
  }

  .calendar-flippers {
    bottom: initial;
  }

  .flipper-button {
    box-shadow: none;
    background: rgba($secondary, 0.2);
    color: $white;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    width: rem(20);
    height: rem(20);
    @include transitions(0.5s);

    svg {
      display: none;
    }

    &:before {
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: rem(8);
    }

    &:first-child {
      &:before {
        content: "\e915";
      }
    }

    &:last-child {
      &:before {
        content: "\e901";
      }
    }

    &:hover {
      background: $teal;
    }
  }

  .dialog-date-picker {
    opacity: 1 !important;
    transform: scale(1) !important;
    position: initial !important;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    box-shadow: none;
    @include borderRadius(0px);
    width: 100%;
  }

  .dialog-header {
    display: none;
  }

  .dialog-content {
    border: none;
  }

  .calendar-wrapper {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  .calendar-content {
    display: flex;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    white-space: nowrap;
    column-gap: rem(38);
  }

  .month-calendar {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    width: 50%;
    display: inline-block;
    vertical-align: top;
  }

  .month-name {
    font-size: rem(18);
    font-weight: 700;
    text-transform: uppercase;
  }

  .weekdays {
    font-size: rem(11);
    font-weight: 700;
    text-transform: uppercase;
    color: $secondary;
    border-bottom: solid 1px $secondary;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
  }

  .day {
    font-size: rem(11);
    font-weight: 900;
    width: rem(73);
    height: rem(70);
    border-radius: 8px !important;
    justify-content: flex-end;
    align-content: flex-start;
    align-items: flex-start;
    background: rgba(227, 222, 219, 0.5);
    border: solid 1px rgba(227, 222, 219, 0.5);
    @include ForMarginPadding(padding, rem(5), rem(5), rem(5), rem(5));
    @include transitions(0.5s);

    &:after {
      width: initial !important;
      height: initial !important;
      border-radius: 0 !important;
      background: none !important;
      border: none !important;
      top: rem(8) !important;
      @include leftToRight(rem(8));
      right: initial !important;
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      font-size: rem(14);
      content: "\e91c";
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    &:hover {
      color: $white;
      background: $teal;
      border-color: $teal;
    }

    &.selected {
      background: $teal;
      border-color: $teal;

      &.hovered {
        background: $teal;
        border-color: $teal;

        &.end {
          background: $teal;
          border-color: $teal;
          color: $white;

          &:after {
            font-size: rem(16);
            content: "\e91f";
          }
        }

        &:hover {
          background: $teal;
          border-color: $teal;
          color: $white;
        }
      }

      &:hover {
        background: $teal;
        color: $white;
        border-color: $teal;
      }
    }

    &.hovered {
      background: $bookingSystemTabs;
      border-color: $bookingSystemTabs;
      color: $white;

      &:hover {
        background: $bookingSystemTabs;
        border-color: $bookingSystemTabs;
        color: $white;
      }

      &.end {
        background: $teal;
        border-color: $teal;
        color: $white;
      }
    }

    &.disabled {
      border-color: $grey;
      color: $grey;
      background: $white;
    }

    &.highlight {
      &:before {
        display: none;
      }

      background: $primary;
      border-color: $primary;
      color: $white;
    }
  }

  .week {
    column-gap: rem(5);
  }

  .background-day {
    display: none;
  }
}


/* Flight Range DatePicker */

@include for-desktop() {
  .react-datepicker {
    &__navigation {
      &:hover {
        background: $teal;
      }
    }
  }
}


@include for-phone-portrait(){
  .dateDropDown{
    width: rem(300);
    @include RightToLeft(0);
  }
}

.our-process-section-wrapper {
  width: 100%;
  // height: rem(400);
  @include ForMarginPadding(padding, rem(100), rem(10), rem(100), rem(10));
  background: $gray_primary;
  // background: linear-gradient(
  //     180deg,
  //     rgba(24, 25, 23, 0.7) 57.81%,
  //     #191817 100%
  //   ),
  //   linear-gradient(
  //     360deg,
  //     rgba(24, 25, 23, 0) 67.84%,
  //     rgba(24, 25, 23, 0.7) 100%
  //   ),
  //   linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  // background-blend-mode: normal, multiply, normal, normal;
  color: $white;
  overflow: hidden;
  position: relative;
  @include transitions(0.5s);

  &:before {
    position: absolute;
    @include leftToRight(0);
    top: 0;
    // width: rem(586);
    width: 100%;
    height: 100%;
    background-image: url("/svgs/vertical-lines.svg");
    background-repeat: repeat-x;
    // background-position: -50% 0;
    background-position: center;
    background-size: cover;
    content: "";
    // z-index: -1;
  }
  .ourProcessHeading {
    color: $primary-wattone;
    text-align: center;
    font-size: $h1-font-size;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }
      p:first-child {
        color: $gray-600;
        font-weight: normal;
        @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
      }
    }
  }
  .ourProcessSlugan {
    text-transform: uppercase;
    color: $secondary;
    font-size: 10px;
    letter-spacing: 5px;
    text-align: center;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
  }

  // .ourProcessHeadline {
  //   color: $primary;
  // }

  // .ourProcessDescription {
  //   text-align: justify;
  //   color: $gray-400;
  // }
  .ourProcessSlider {
    .slider-container {
      height: 100px;
      overflow: hidden;
      position: relative;

      .slider-track {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
      }

      .slider-progress {
        height: 3px;
        background-color: $primary;
        position: absolute;
        bottom: 0;
        @include transitions(0.5s);
      }

      .slider-icons {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .slider-icon {
          width: 20%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          i {
            font-size: 35px;
            color: $gray-400;
            @include transitions(0.5s);
            text-align: center;
            font-style: normal;
            // position: absolute;
            // top: 0;
            @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
            p {
              font-size: 14px;
              color: $gray-400;
              @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
              font-weight: 800;
            }
          }

          &.active i {
            color: $primary;
          }
        }
      }
    }
  }
  .ourProcessSliderContent {
    p {
      text-align: justify;
      color: $gray-400;
      // font-size: 1px;
    }
  }
}

@include for-tablet-landscape() {
  .our-process-section-wrapper {
    .ourProcessHeading {
      font-size: $h2-font-size;
    }
    .ourProcessSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourProcessSlider {
      .slider-container {
        height: 85px;

        .slider-icons {
          .slider-icon {
            i {
              font-size: 35px;
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@include for-tablet-portrait() {
  .our-process-section-wrapper {
    .ourProcessHeading {
      font-size: $h2-font-size;
    }
    .ourProcessSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourProcessSlider {
      .slider-container {
        height: 85px;
        .slider-icons {
          .slider-icon {
            i {
              font-size: 35px;
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}

@include for-phone-landscape() {
  .our-process-section-wrapper {
    .ourProcessHeading {
      font-size: $h3-font-size;
    }
    .ourProcessSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourProcessSlider {
      .slider-container {
        height: 70px;
        .slider-icons {
          .slider-icon {
            i {
              font-size: 30px;
              p {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@include for-phone-portrait() {
  .our-process-section-wrapper {
    .ourProcessHeading {
      font-size: $h3-font-size;
    }
    .ourProcessSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourProcessSlider {
      .slider-container {
        height: 70px;
        .slider-icons {
          .slider-icon {
            i {
              font-size: 30px;
              p {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

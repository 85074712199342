.btn {
  position: relative;
  @include transitions(0.5s);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  min-width: rem(167);

  span {
    display: block;

    + i {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(15));
    }
  }

  i {
    + span {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(15));
    }

    &:before {
      font-size: rem(14);
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    color: $white;
    @include ForMarginPadding(padding, rem(11), rem(20), rem(11), rem(20));

    &:hover {
      background: darken($value, 5%);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
      color: $white;
    }

    &:focus, &:active, &:first-child:active {
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
      background: $value;
      color: $white;
    }

    @if $color == "white" {
      color: $secondary;
      &:hover {
        color: $secondary;
      }
      &:focus, &:active, &:first-child:active {
        color: $secondary;
      }
    }
  }
}

.btn-arrow-secondary {
  span {
    background: linear-gradient(
                    to bottom,
                    rgba($secondary, 1) 0%,
                    rgba($secondary, 1) 98%
    );
  }
}

@each $color, $value in $theme-colors {
  .btn-arrow-#{$color} {
    color: $value;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    text-decoration: none;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    line-height: 1;

    span {
      display: inline;
      padding-bottom: 2px;
      transition: all 0.5s linear;
      background: linear-gradient(
                      to bottom,
                      rgba($value, 1) 0%,
                      rgba($value, 1) 98%
      );
      background-size: 0 1px;
      background-repeat: no-repeat;
      background-position: left 100%;
      @include rtl() {
        background-position: right 100%;
      }

      + i {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(15));
      }
    }

    i {
      position: relative;
      top: rem(-2);

      + span {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(15));
      }

      &:before {
        font-size: rem(12);
      }
    }

    &:hover {
      color: $value;

      span {
        background-size: 100% 1px;
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    border-style: solid;
    border-color: $value;
    border-width: 1px;
    @include ForMarginPadding(padding, rem(9), rem(20), rem(9), rem(20));

    &:hover, &:focus, &:active, &:first-child:active {
      background: transparent;
      color: $value;
      box-shadow: 0 0 0 1px inset $value;
    }
  }
}


.textLink {
  @include transitions(0.5s);
  font-size: rem(12);
  color: $teal;
  text-decoration: none;

  span {
    display: inline;
    padding-bottom: 2px;
    transition: all 0.5s linear;
    background: linear-gradient(to bottom, rgba($teal, 1) 0%, rgba($teal, 1) 98%);
    background-size: 0 1px;
    background-repeat: no-repeat;
    background-position: left 100%;
    @include rtl() {
      background-position: right 100%;
    }
  }

  i {
    font-size: rem(12);
    @include transitions(0.5s);
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(10));
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}

.textLinkPrimary {
  @include transitions(0.5s);
  font-size: rem(16);
  color: $primary;
  text-decoration: none;

  span {
    display: inline;
    padding-bottom: 2px;
    transition: all 0.5s linear;
    background: linear-gradient(to bottom, rgba($primary, 1) 0%, rgba($primary, 1) 98%);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: left 100%;
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}

.textLinkSecondary {
  @include transitions(0.5s);
  color: $secondary;
  text-decoration: none;

  span {
    display: inline;
    padding-bottom: 2px;
    transition: all 0.5s linear;
    background: linear-gradient(to bottom, rgba($secondary, 1) 0%, rgba($secondary, 1) 98%);
    background-size: 100% 1px;
    background-repeat: no-repeat;
    background-position: left 100%;
  }

  &:hover, &:focus {
    text-decoration: none;
  }
}


@include for-desktop() {
  .textLink {
    &:hover {
      color: $teal;

      span {
        background-size: 100% 1px;
      }

      i {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(20));
      }
    }
  }
  .textLinkPrimary {
    &:hover {
      color: $primary;

      span {
        background-size: 0 1px;
      }
    }
  }
  .textLinkSecondary {
    &:hover {
      color: $secondary;

      span {
        background-size: 0 1px;
      }
    }
  }
}

@include for-phone-landscape() {
  .textLinkPrimary {
    font-size: rem(13);
  }
}

@include for-phone-portrait() {
  .textLinkPrimary {
    font-size: rem(13);
  }
}
@mixin for-extra-large-screens() {
  // Laptops large devices (desktops, 1600px and up)
  @media (min-width: 1600px) and (max-width: 1699.98px) {
  }
}

@mixin for-large-screens() {
  // Laptops large devices (desktops, 1400px and up)
  @media (min-width: 1400px) and (max-width: 1599.98px) {
    @content;
  }
}

@mixin for-desktop() {
  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-laptop() {
  // Laptops small devices (desktops, 1200px and up)
  @media (min-width: 1200px) and (max-width: 1399.98px) {
    @content;
  }
}

@mixin for-tablet-landscape() {
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) and (max-width: 1199.98px) {
    @content;
  }
}

@mixin for-tablet-portrait() {
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) and (max-width: 991.98px) {
    @content;
  }
}

@mixin for-phone-landscape() {
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) and (max-width: 767.98px) {
    @content;
  }
}

@mixin for-phone-portrait() {
  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575.98px) {
    @content;
  }
}

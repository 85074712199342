.screenTakeOverSlider {
  position: relative;
  height: 100vh;
  background: $secondary;

  @include ForMarginPadding(margin, rem(0), rem(0), rem(80), rem(0));
}

.fullImgSlider {
  height: 100%;

  .slick-list,
  .slick-track,
  .slick-slide {
    height: 100%;
  }

  .slick-slide {
    > div {
      width: 100%;
      height: 100%;

      > div {
        width: 100%;
        height: 100%;
      }
    }

    &.slick-active {
      .fullImage {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
      }
    }
  }

  .slick-dots {
    position: absolute;
    @include RightToLeft(rem(40));
    top: 0;
    bottom: initial;
    width: rem(12);
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;

    li {
      width: rem(12) !important;
      height: rem(12) !important;
      @include ForMarginPadding(margin, rem(5), rem(0), rem(5), rem(0));

      button {
        background: $grey;
        @include borderRadius(50%);
        @include transitions(0.5s);
        border: none;
        width: 100%;
        height: 100%;
      }

      &.slick-active {
        button {
          background: $teal;
        }
      }
    }
  }
}

.fullImage {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @include transitions(1s);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.2);
  background-color: $secondary;
}

.textSlider {
  position: absolute;
  @include leftToRight(0);
  @include RightToLeft(0);
  z-index: 1;
  top: rem(250);
}

.detailBox {
  background: rgba($white, 0.8);
  @include borderRadius(4px);
  @include ForShadow('large');
  @include ForMarginPadding(padding, rem(30), rem(30), rem(30), rem(30));

  .animateElement {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
    overflow: hidden;
    h2,
    p,
    .btn {
      opacity: 0;
      visibility: hidden;
      @include transitions(0.5s);
      transform: translateY(100%);
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    }

    &:last-child {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    }
  }

  .slick-slide {
    &.slick-active {
      .animateElement {
        h2,
        p,
        .btn {
          opacity: 1;
          visibility: visible;
          transform: translateY(0%);
          transition-delay: 0.5s;
        }
      }
    }
  }
}

@include for-phone-landscape() {
  .screenTakeOverSlider {
    height: rem(600);
  }
  .fullImgSlider {
    .slick-dots {
      width: 100%;
      top: initial;
      bottom: rem(30);
      flex-direction: row;
      @include RightToLeft(rem(0));
      height: initial;
      li {
        @include ForMarginPadding(margin, rem(0), rem(5), rem(0), rem(5));
      }
    }
  }
  .textSlider {
    top: rem(100);
  }
  .detailBox {
    @include ForMarginPadding(padding, rem(20), rem(30), rem(20), rem(30));
  }
}

@include for-phone-portrait() {
  .screenTakeOverSlider {
    height: rem(600);
  }
  .fullImgSlider {
    .slick-dots {
      width: 100%;
      top: initial;
      bottom: rem(30);
      flex-direction: row;
      @include RightToLeft(rem(0));
      height: initial;
      li {
        @include ForMarginPadding(margin, rem(0), rem(5), rem(0), rem(5));
      }
    }
  }
  .textSlider {
    top: rem(100);
  }
  .detailBox {
    @include ForMarginPadding(padding, rem(20), rem(30), rem(20), rem(30));
  }
}

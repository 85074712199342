.text-and-cards-module{
  @include ForMarginPadding(padding, rem(84), rem(0), rem(84), rem(0));
  background: $black;
  color: $white;
  .description-box{
    @include ForMarginPadding(margin, rem(0), rem(0), rem(84), rem(0));
  }
}

.text-slide-list{
  .item{
    width: 31.5%;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(40), rem(0));
  }
  .slider{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.manageBookingBannerWrapper{
  bottom: 0;
  .form-group{
    width: 36.5%;
  }
  .btn{
    @include ForMarginPadding(padding, rem(12), rem(32) ,rem(12), rem(32))
  }
  .icon-question {
    position: absolute;
    top: rem(5);
    right: 0;
    font-size: rem(20);
  }
}

#booking-reference-number{
  position: relative;
}

@include for-phone-portrait(){
  .text-slide-list{
    .slick-slide{
      width: rem(320);
      @include ForMarginPadding(margin, rem(0), rem(20), rem(0), rem(0));
    }
    .item{
      height: rem(354);
    }
  }
}
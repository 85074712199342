.footer-wrapper {
  background: $gray_primary;
  color: $white;
  position: relative;
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  @include ForDirection(text-align, center, center);
  @include ForDirection(align-items, center, center);
}

.footer-social-icons-outer {
  border-bottom: 1px solid rgba($primary, 0.5);
  // background-color: $primary;
  @include ForMarginPadding(padding, rem(18), rem(0), rem(20), rem(0));
  @include ForDirection(align-items, center, center);
  @include ForDirection(justify-content, center, center);
}

.footer-bottom {
  @include ForMarginPadding(padding, rem(20), rem(0), rem(60), rem(0));
  // background: $white;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    color: $primary;
  }

  .footer-bottom-copyright {
    @include ForDirection(text-align, left, left);
  }
  .footer-bottom-logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.social-icon-box {
  .logo {
    width: rem(171);
    display: block;
  }
}

.footer-social-icons {
  ul {
    @include ForMarginPadding(margin, rem(32), rem(0), rem(30), rem(0));
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    @include ForDirection(align-items, center, center);
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      list-style: none;
      @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
      width: rem(40);
      height: rem(40);

      a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        background: $white;
        color: $secondary;
        font-size: rem(18);
        line-height: 1;
        @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));
        @include transitions(0.5s);
        @include borderRadius(7px);
        &:hover {
          background-color: $primary;
          color: $white;
        }
      }

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }
    }
  }
}

/** Footer Layer Styles ***/
// .footer-decoration {
//   width: 100%;
//   // height: rem(130);
//   position: absolute;
//   bottom: 100%;
//   overflow: hidden;
//   pointer-events: none;

//   .decoration-layer {
//     width: 102.2%;
//     height: rem(130);
//     position: absolute;
//     border-radius: 0 rem(120) 0 0;
//     transform-origin: right;
//     top: 0;
//     left: 0;

//     &.one {
//       background: $grey;
//       @include transform(skewY(1.9deg));
//       top: 49.5%;
//       //@include leftToRight(rem(-5));
//       left: rem(-5);
//       transform-origin: right;
//     }

//     &.two {
//       background: $lightGrey;
//       @include transform(skewY(0.5deg));
//       top: 49.5%;
//       transform-origin: right;
//       //@include leftToRight(rem(-3));
//       left: rem(-3);
//     }

//     &.three {
//       background: $specialFooter;
//       @include transform(skewY(-1.5deg));
//       top: 50%;
//       transform-origin: right;
//     }
//   }
// }

// @include for-desktop {
//   .footer-social-icons {
//     ul {
//       li {
//         a {
//           &:hover {
//             background-color: $secondary;
//             color: $white;
//           }
//         }
//       }
//     }
//   }
// }

@include for-tablet-landscape {
  .footer-wrapper {
    .footer-top {
      .footer-quick-links-heading {
        max-width: 80%;
      }

      .footer-quick-links {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
      }

      .social-icon-box {
        @include ForDirection(text-align, left, right);

        .footer-social-icons {
          @include ForDirection(text-align, left, right);
        }

        .footer-awards {
          @include ForDirection(text-align, left, right);
        }
      }
    }

    .footer-social-icons-outer {
    }
  }
}

@include for-tablet-portrait {
  .footer-wrapper {
    .footer-bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .footer-bottom-copyright {
        @include ForDirection(text-align, center, center);
        @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
      }

      .footer-bottom-links {
        @include ForDirection(text-align, center, center);
      }
    }
  }

  .social-icon-box {
    align-items: flex-start;
  }
}

@include for-phone-landscape {
  .footer-wrapper {
    .container {
      --width: #{rem(26)};
      max-width: calc(100% - var(--width));
    }
  }

  .footer-decoration {
    .decoration-layer {
      width: 109%;
      height: rem(82);

      &.one {
        transform: skewY(4.9deg);
        top: 51.5%;
      }

      &.two {
        transform: skewY(0.8deg);
        top: 51.5%;
      }

      &.three {
        transform: skewY(-5.5deg);
      }
    }
  }

  .footer-social-icons-outer {
    @include ForMarginPadding(padding, rem(20), rem(0), rem(30), rem(0));
    border-bottom: 0;
  }

  .social-icon-box {
    align-items: center;
    @include ForMarginPadding(padding, rem(20), rem(0), rem(0), rem(0));
  }

  .footer-social-icons {
    ul {
      @include ForMarginPadding(margin, rem(30), rem(0), rem(0), rem(0));
    }
  }

  .footer-bottom {
    // background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .copywrite-txt {
    @include ForDirection(text-align, center, center);
    @include ForMarginPadding(margin, rem(0), rem(0), rem(4), rem(0));
  }
}

@include for-phone-portrait {
  .footer-wrapper {
    .container {
      --width: #{rem(26)};
      max-width: calc(100% - var(--width));
    }
  }

  .footer-decoration {
    .decoration-layer {
      width: 109%;
      height: rem(82);

      &.one {
        transform: skewY(4.9deg);
        top: 51.5%;
      }

      &.two {
        transform: skewY(0.8deg);
        top: 51.5%;
      }

      &.three {
        transform: skewY(-5.5deg);
      }
    }
  }

  .footer-social-icons-outer {
    @include ForMarginPadding(padding, rem(20), rem(0), rem(30), rem(0));
    border-bottom: 0;
  }

  .social-icon-box {
    align-items: center;
    @include ForMarginPadding(padding, rem(20), rem(0), rem(0), rem(0));
  }

  .footer-social-icons {
    ul {
      @include ForMarginPadding(margin, rem(30), rem(0), rem(0), rem(0));
    }
  }

  .footer-bottom {
    // background-color: $white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .copywrite-txt {
    @include ForDirection(text-align, center, center);
    @include ForMarginPadding(margin, rem(0), rem(0), rem(4), rem(0));
  }

  .copywrite-txt {
    font-size: rem(12);
  }
}

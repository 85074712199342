@import '~animate.css/animate.css';
@media (min-width: 1200px) {
  .slideFadeDown{
    transform: translateY(25px);
    opacity: 0;
    @include transitions(0.7s);
  }
  .animate {
    filter: alpha(opacity=0);
    opacity: 0;
    &__animated{
      filter: alpha(opacity=100);
      opacity: 1;
    }
    &__slideFadeUp{
      transform: translateY(0px);
    }
  }
}

.fadeZoomOut {
  opacity: 0;
  transform: scale(0.9, 0.9);
  @include transitions(0.5s);
}
.animate {
  filter: alpha(opacity=0);
  opacity: 0;
  &__animated{
    filter: alpha(opacity=100);
    opacity: 1;
  }
  &__fadeZoomIn{
    transform: scale(1, 1);
  }
}

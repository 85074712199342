.loginPopup {
  background: $white;
  @include transitions(0.5s);
}

.checkBoxWrap {
  display: flex;
  align-items: center;
  @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

  .form-check {
    @include ForMarginPadding(margin, rem(0), rem(20), rem(0), rem(0));

    &:last-child {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    }
  }
}

.btnWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .btn {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(15));
  }
}

@include for-desktop() {
  .loginPopup {
    position: absolute;
    @include leftToRight(rem(50));
    top: calc(100% + 20px);
    width: rem(375);
    @include borderRadius(10px);
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));
    z-index: 2;
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 20px);

    &:before {
      content: "";
      position: absolute;
      @include RightToLeft(rem(0));
      @include leftToRight(0);
      @include ForMarginPadding(margin, auto, auto, auto, auto);
      top: rem(-13);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 13px solid rgba($white, 1);
      @include transitions(0.5s);
    }

    .text-xl {
      color: $primary;
    }

    .form-control {
      &.secondry {
        font-size: rem(14);
      }
    }

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 20px);
    }


    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, 0);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 20px);
    }
  }
}

@include for-tablet-landscape() {
  .loginPopup {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 9;

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}

@include for-tablet-portrait() {
  .loginPopup {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 9;

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}

@include for-phone-landscape() {
  .loginPopup {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 9;

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}

@include for-phone-portrait() {
  .loginPopup {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 9;

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}
.flyerFormBox{
  background-color: $grey;
  height: 100%;
  @include ForMarginPadding(padding, rem(40),rem(40),rem(40),rem(40));
  @include ForDirection(text-align, center, center);
  @include borderRadius(rem(10) rem(10) rem(10) rem(10));
  .log-in-btn{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid $secondary;
    @include ForMarginPadding(margin, rem(38),rem(0),rem(0),rem(0));
    @include ForMarginPadding(padding, rem(38),rem(0),rem(0),rem(0));
    p{
      @include ForMarginPadding(margin, rem(0),rem(0),rem(0),rem(0));
    }
  }
  h2{
    letter-spacing: -2px;
  }
  &.white-bg{
    background-color: $white;
  }
}



@include for-tablet-portrait(){
  .flyerFormBox{
    @include ForMarginPadding(padding, rem(30),rem(20),rem(30),rem(20));
  }
}


@include for-phone-portrait(){
  .flyerFormBox{
    @include ForMarginPadding(padding, rem(40),rem(20),rem(40),rem(20));
    @include borderRadius(rem(10) rem(10) rem(10) rem(10));
  }
}
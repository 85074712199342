.our-services-section-wrapper {
  width: 100%;
  // height: rem(400);
  @include ForMarginPadding(padding, rem(100), rem(10), rem(100), rem(10));
  background: $gray_primary;
  color: $white;
  overflow: hidden;
  position: relative;
  @include transitions(0.5s);
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;

  .ourServicesHeading {
    color: $primary;
    text-align: left;
    font-size: $h1-font-size;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }
      p:first-child {
        color: $gray-600;
        font-weight: normal;
        @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
      }
    }
  }

  .ourServicesSlugan {
    color: $secondary;
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 5px;
    text-align: center;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
  }

  .slider-outer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    // @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

    .slider {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-x: scroll;
      -webkit-overflow-scrolling: touch;
      scroll-behavior: smooth;
      @include ForMarginPadding(padding, rem(50), rem(0), rem(50), rem(0));
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      &::-webkit-scrollbar {
        height: 0.75 * 1rem;
      }

      &::-webkit-scrollbar-track {
        background: $primary;
      }

      &::-webkit-scrollbar-thumb {
        background: $secondary;
        border-radius: 0.75 * 2px;
      }

      &.is-dragging {
        cursor: grabbing;
        // cursor: url("https://ssl.gstatic.com/ui/v1/icons/mail/images/2/openhand.cur");
          // default !important;
        user-select: none;
      }

      &__slide {
        display: flex;
        flex-wrap: nowrap;
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      }

      .service-card {
        @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(10));
        // height: 25rem;
        @include transitions(0.5s);
        width: 0.75 * 20rem;
        .icon-box-outer {
          // &:after {
          //   position: absolute;
          //   @include leftToRight(0);
          //   top: 0;
          //   // width: rem(586);
          //   width: 100%;
          //   height: 100%;
          //   background-image: url("/images/service-card.png");
          //   background-repeat: repeat-x;
          //   // background-position: -50% 0;
          //   background-position: center;
          //   background-size: cover;
          //   content: "";
          //   z-index: -1;
          // }
          padding: 0.75 * 2rem;
          padding-top: 0.75 * 13.7rem;
          position: relative;
          overflow: hidden;
          box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
          border: 1px solid $primary;
          transition: all 0.3s ease-in-out;
          height: 0.75 * 27rem;
          &:hover {
            transform: translateY(-0.75 * 2rem);
            @include transitions(0.5s);
          }
          .icon-box-inner {
            /* overflow: hidden; */
            transform: translateY(0.75 * 8rem);
            transition: 0.5s;
            opacity: 1;
            transition: all 0.3s ease-in-out;
            .title {
              color: $gray-600;
              font-weight: 900;
              font-size: 0.75 * 1.2rem;
              @include transitions(0.5s);
              &:after {
                content: "";
                position: relative;
                display: block;
                width: 100%;
                height: 0.75 * 3px;
                background: $primary;
                margin-top: 0.75 * 5%;
                @include transitions(0.5s);
              }
            }
            .description {
              color: $gray-400;
              font-size: 0.75 * 16px;
              line-height: 0.75 * 24px;
              margin-bottom: 0;
              margin-top: 0.75 * 2rem;
              @include transitions(0.5s);
            }
          }
        }
        .icon-box-outer:hover .icon-box-inner,
        .icon {
          @include transitions(0.5s);
          transform: translateY(-0.75 * 4.5rem);
          opacity: 1;
          .title {
            color: $gray-200;
            @include transitions(0.5s);
          }
        }
      }
    }
  }
}

@include for-laptop() {
  .our-services-section-wrapper {
    .slider-outer {
      .slider {
        .service-card {
          @include transitions(0.5s);
          .icon-box-outer {
            @include transitions(0.5s);
            padding-top: 0.75 * 16.7rem;
            height: 0.75 * 30rem;
          }
          .icon-box-outer:hover .icon-box-inner,
          .icon {
            @include transitions(0.5s);
            transform: translateY(-0.75 * 7rem);
            opacity: 1;
            .title {
              color: $gray-200;
              @include transitions(0.5s);
            }
          }
        }
      }
    }
  }
}
@include for-tablet-landscape() {
  .our-services-section-wrapper {
    .ourServicesHeading {
      font-size: $h2-font-size;
    }
    .ourServicesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .slider-outer {
      .slider {
        .service-card {
          @include transitions(0.5s);
          .icon-box-outer {
            @include transitions(0.5s);
            padding-top: 0.75 * 16.7rem;
            height: 0.75 * 30rem;
          }
          .icon-box-outer:hover .icon-box-inner,
          .icon {
            @include transitions(0.5s);
            transform: translateY(-0.75 * 7rem);
            opacity: 1;
            .title {
              color: $gray-200;
              @include transitions(0.5s);
            }
          }
        }
      }
    }
  }
}
@include for-tablet-portrait() {
  .our-services-section-wrapper {
    .ourServicesHeading {
      font-size: $h2-font-size;
    }
    .ourServicesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .slider-outer {
      .slider {
        .service-card {
          .icon-box-outer {
            @include transitions(0.5s);
            padding-top: 0.75 * 16.7rem;
            height: 0.75 * 30rem;
          }
          .icon-box-outer:hover .icon-box-inner,
          .icon {
            @include transitions(0.5s);
            transform: translateY(-0.75 * 7rem);
            opacity: 1;
            .title {
              color: $gray-200;
              @include transitions(0.5s);
            }
          }
        }
      }
    }
  }
}
@include for-phone-landscape() {
  .our-services-section-wrapper {
    .ourServicesHeading {
      font-size: $h3-font-size;
    }
    .ourServicesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .slider-outer {
      .slider {
        .service-card {
          width: 0.75 * 18rem;
        }
      }
    }
  }
}
@include for-phone-portrait() {
  .our-services-section-wrapper {
    .ourServicesHeading {
      font-size: $h3-font-size;
    }
    .ourServicesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .slider-outer {
      .slider {
        .service-card {
          width: 0.75 * 18rem;
        }
      }
    }
  }
}

.travelNewsAlertWrapper {
  position: absolute;
  @include leftToRight(0);
  top: rem(140);
  width: 100%;

  p {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    .textLink{
      color: $secondary;
      font-size: rem(14);
      span{
        background: linear-gradient(to bottom, rgba($secondary, 1) 0%, rgba($secondary, 1) 98%);
        background-size: 100% 1px;
        background-repeat: no-repeat;
        background-position: left 100%;
      }
    }
  }
}

.travelAlert {
  background: rgba($bookingContentTabs, 0.8);
  backdrop-filter: blur(7.5px);
  @include ForMarginPadding(padding, rem(15), rem(50), rem(15), rem(50));
  @include borderRadius(8px);
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.travelUpdate{
  width: 12%;
}

.travelUpdateSlider{
  width: 88%;
  overflow: hidden;
}

.alertIcon {
  position: absolute;
  @include leftToRight(0);
  top: 0;
  width: rem(30);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: $teal;
}

.closeAlert {
  position: absolute;
  @include RightToLeft(0);
  top: 0;
  width: rem(30);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.closeAlertBtn {
  background: none;
  border: none;
  @include borderRadius(50%);
  width: rem(18);
  height: rem(18);
  display: flex;
  align-items: center;
  justify-content: center;
  background: $lightGrey;
  color: $white;
  @include transitions(0.5s);
  font-size: rem(16);
  line-height: 1;

  i {
    position: relative;
    top: rem(-4);
    @include leftToRight(0.5px);

    &:before {
      font-size: rem(6);
    }
  }
}



@include for-desktop(){
  .travelNewsAlertWrapper{
    p{
      .textLink{
        &:hover{
          span{
            background-size: 0 1px;
          }
        }
      }
    }
  }
  .closeAlertBtn{
    &:hover{
      background: $teal;
    }
  }
}
@include for-tablet-landscape(){}
@include for-tablet-portrait(){
  .travelUpdate{
    width: 20%;
  }
  .travelUpdateSlider{
    width: 80%;
  }
}
@include for-phone-landscape(){
  .travelNewsAlertWrapper{
    top: rem(100);
  }
  .alertIcon{
    height: auto;
    width: rem(50);
    justify-content: center;
    @include ForMarginPadding(padding, rem(20), rem(0), rem(0), rem(0));
  }
  .closeAlert{
    height: auto;
    width: rem(50);
    justify-content: center;
    @include ForMarginPadding(padding, rem(15), rem(0), rem(0), rem(0));
  }
  .travelAlert{
    display: block;
  }
  .travelUpdate{
    width: 100%;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
  }
  .travelUpdateSlider{
    width: 100%;
  }
}
@include for-phone-portrait(){
  .travelNewsAlertWrapper{
    top: rem(100);
  }
  .alertIcon{
    height: auto;
    width: rem(50);
    justify-content: center;
    @include ForMarginPadding(padding, rem(20), rem(0), rem(0), rem(0));
  }
  .closeAlert{
    height: auto;
    width: rem(50);
    justify-content: center;
    @include ForMarginPadding(padding, rem(15), rem(0), rem(0), rem(0));
  }
  .travelAlert{
    display: block;
  }
  .travelUpdate{
    width: 100%;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
  }
  .travelUpdateSlider{
    width: 100%;
  }
}
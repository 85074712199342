.single-slide-banner {
  min-height: rem(690);
  position: relative;
  .single-slide-bg {
    position: absolute;
    @include leftToRight(0);
    top: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    &:before {
      position: absolute;
      content: '';
      @include leftToRight(0);
      width: 90%;
      height: 100%;
      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#14141f+0,14141f+60,14141f+100&0.8+0,0.8+60,0.3+81,0.01+100 */
      background: -moz-linear-gradient(
        left,
        rgba(20, 20, 31, 0.8) 0%,
        rgba(20, 20, 31, 0.8) 60%,
        rgba(20, 20, 31, 0.3) 81%,
        rgba(20, 20, 31, 0.01) 100%
      ); /* FF3.6-15 */
      background: -webkit-linear-gradient(
        left,
        rgba(20, 20, 31, 0.8) 0%,
        rgba(20, 20, 31, 0.8) 60%,
        rgba(20, 20, 31, 0.3) 81%,
        rgba(20, 20, 31, 0.01) 100%
      ); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(
        to right,
        rgba(20, 20, 31, 0.8) 0%,
        rgba(20, 20, 31, 0.8) 60%,
        rgba(20, 20, 31, 0.3) 81%,
        rgba(20, 20, 31, 0.01) 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc14141f', endColorstr='#0314141f',GradientType=1 ); /* IE6-9 */
    }
  }
  .container {
    position: relative;
    z-index: 2;
  }
  .single-slide-content {
    @include ForMarginPadding(padding, rem(140), rem(0), rem(80), rem(0));
    color: $white;
    .btn {
      @include ForMarginPadding(margin, rem(14), rem(10), rem(0), rem(0));
    }
  }
}

.color-primary {
  color: $primary;
}

@include for-phone-landscape() {
  .single-slide-banner {
    min-height: rem(550);
    .single-slide-bg {
      &:before {
        width: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#14141f+0,14141f+100&0.3+0,0.8+100 */
        background: -moz-linear-gradient(
          top,
          rgba(20, 20, 31, 0.3) 0%,
          rgba(20, 20, 31, 0.8) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(20, 20, 31, 0.3) 0%,
          rgba(20, 20, 31, 0.8) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(20, 20, 31, 0.3) 0%,
          rgba(20, 20, 31, 0.8) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d14141f', endColorstr='#cc14141f',GradientType=0 ); /* IE6-9 */
      }
    }
    .single-slide-content {
      @include ForMarginPadding(padding, rem(40), rem(0), rem(40), rem(0));
      @include ForDirection(text-align, center, center);
    }
  }
}

@include for-phone-portrait() {
  .single-slide-banner {
    min-height: rem(550);
    .single-slide-bg {
      &:before {
        width: 100%;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#14141f+0,14141f+100&0.3+0,0.8+100 */
        background: -moz-linear-gradient(
          top,
          rgba(20, 20, 31, 0.3) 0%,
          rgba(20, 20, 31, 0.8) 100%
        ); /* FF3.6-15 */
        background: -webkit-linear-gradient(
          top,
          rgba(20, 20, 31, 0.3) 0%,
          rgba(20, 20, 31, 0.8) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
          to bottom,
          rgba(20, 20, 31, 0.3) 0%,
          rgba(20, 20, 31, 0.8) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4d14141f', endColorstr='#cc14141f',GradientType=0 ); /* IE6-9 */
      }
    }
    .single-slide-content {
      @include ForMarginPadding(padding, rem(40), rem(0), rem(40), rem(0));
      @include ForDirection(text-align, center, center);
    }
  }
}

.mobile-menu-bar {
  display: none;

  ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    list-style: none;

    li {
      @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }

      a {
        font-size: rem(14);
        font-weight: 500;
        line-height: 1;
        color: $white;
        text-decoration: none;
        display: block;

        i {
          &:before {
            font-size: rem(19);
          }
        }
      }

      button {
        background: none;
        border: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

        .menuText {
          font-size: rem(14);
          text-transform: uppercase;
          line-height: 1;
          color: $primary;
          @include ForMarginPadding(margin, rem(0), rem(15), rem(0), rem(0));
        }

        .menuLines {
          width: rem(24);
          height: rem(20);
          display: block;
          position: relative;

          i {
            display: block;
            position: absolute;
            @include leftToRight(0);
            top: 0;
            width: 100%;
            height: 3px;
            @include borderRadius(4px);
            background: $primary;
            @include transitions(0.5s);

            &:nth-child(1) {}

            &:nth-child(2) {
              top: rem(8);
            }

            &:nth-child(3) {
              top: rem(17);
            }
          }
        }

        &.active {
          .menuLines {
            i {
              &:nth-child(1) {
                transform: rotate(45deg);
                top: rem(7);
              }

              &:nth-child(2) {
                opacity: 0;
                visibility: hidden;
              }

              &:nth-child(3) {
                transform: rotate(-45deg);
                top: rem(7);
              }
            }
          }
        }
      }
    }
  }
}

.mobileTopBar {
  position: absolute;
  @include leftToRight(0);
  top: 0;
  background: $primary;
  width: 100%;
  height: rem(68);
  display: none;
  justify-content: space-between;
  align-items: center;
  @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
}

.closeMenu {
  background: none;
  color: $white;
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  border: none;
  line-height: 1;

  i {
    &:before {
      font-size: rem(24);
    }
  }
}

.menuText {
  color: $white;
  display: block;
}

@include for-tablet-landscape() {
  .mobile-menu-bar {
    display: block;
  }
}


@include for-tablet-landscape() {
  .mobile-menu-bar {
    display: block;
  }

  .mobileTopBar {
    display: flex;
  }
}


@include for-tablet-portrait() {
  .mobile-menu-bar {
    display: block;
  }

  .mobileTopBar {
    display: flex;
  }
}

@include for-phone-landscape() {
  .mobile-menu-bar {
    display: block;
  }

  .mobileTopBar {
    display: flex;
  }
}

@include for-phone-portrait() {
  .mobile-menu-bar {
    display: block;
  }

  .mobileTopBar {
    display: flex;
  }
}
header {
  position: fixed;

  &::after {
    border-bottom: 1px;
    border-color: $primary-wattone;
    width: auto
  }

  @include ForMarginPadding(padding, rem(17), rem(0), rem(17), rem(0));
  @include transitions(0.5s);
  // border-radius: 0 0 20px 20px;
  @include leftToRight(0);
  top: 0;
  z-index: 9;
  width: 100%;


  &.scrolled {
    background: rgba($white, 0.9);
    // background: $gray-100;
    // backdrop-filter: 6px;
    // -webkit-backdrop-filter: 6;
    // opacity: 0.9;
    // backdrop-filter: blur(rem(20));
    // -webkit-backdrop-filter: blur(rem(20));
    // border-top: 5px solid;
    // border-top-color: $primary-wattone;
    // border-width: 100%;
    @include ForShadow('large');


    .menu-left,
    .menu-right {

      li {
        a {
          color: $secondary;
          font-weight: 600;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .selectedLang {
      span {
        color: $secondary;

        &:before {
          background: rgba($secondary, 0.2);
        }
      }
    }

    .search-button {
      color: $secondary;
    }

    .lang-search-box {
      >li {
        &:before {
          background: rgba($secondary, 0.2);
        }
      }
    }

    // .mobile-menu-bar {
    //   ul {
    //     li {
    //       button {
    //         .menuText {
    //           color: $secondary;
    //         }

    //         .menuLines {
    //           i {
    //             background: $secondary;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}



.logo {
  display: flex;
  width: rem(158);
  height: rem(30);
  align-items: center;
  @include ForMarginPadding(margin, rem(12), rem(0), rem(0), rem(0));

  img {
    @include transitions(0.5s);
  }
}

.headWrapper {
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(30));
  opacity: 1 !important;
}

.wrapperMenu {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.header-top-nav {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @include ForMarginPadding(margin, rem(0), rem(0), rem(27), rem(0));
}

.header-bottom-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


@import "header-top/loginContainer";
@import "header-top/langSearchContainer";
@import "header-bottom/menuLeft";
@import "header-bottom/menuRight";
@import "header-top/loginPopup";
@import "search";

@include for-tablet-landscape() {
  header {
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));
  }

  .headWrapper {
    position: fixed;
    @include RightToLeft(-100%);
    @include transitions(0.5s);
    top: 0;
    width: 30%;
    height: 100%;
    z-index: 99;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    background: $white;
    box-shadow: 0 0 15px rgba($black, 0.2);

    &.active {
      @include RightToLeft(0);
    }
  }

  .wrapperMenu {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .header-top-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  .header-bottom-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }
}

@include for-tablet-portrait() {
  header {
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));
  }

  .headWrapper {
    position: fixed;
    @include RightToLeft(-100%);
    @include transitions(0.5s);
    top: 0;
    width: 50%;
    height: 100%;
    z-index: 99;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    background: $white;
    box-shadow: 0 0 15px rgba($black, 0.2);

    &.active {
      @include RightToLeft(0);
    }
  }

  .wrapperMenu {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .header-top-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  .header-bottom-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }
}

@include for-phone-landscape() {
  header {
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));
  }

  .logo {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .headWrapper {
    position: fixed;
    @include RightToLeft(-100%);
    @include transitions(0.5s);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    background: $white;
    overflow-y: scroll;

    &.active {
      @include RightToLeft(0);
    }
  }

  .wrapperMenu {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .header-top-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  .header-bottom-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }
}

@include for-phone-portrait() {
  header {
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));
  }

  .logo {
    width: 100%;

    img {
      width: 100%;
      height: auto;
    }
  }

  .headWrapper {
    position: fixed;
    @include RightToLeft(-100%);
    @include transitions(0.5s);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    background: $white;
    overflow-y: scroll;

    &.active {
      @include RightToLeft(0);
    }
  }

  .wrapperMenu {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }

  .header-top-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  .header-bottom-nav {
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }
}
.faq-section{
  background-color: $specialBackground;
  @include ForMarginPadding(padding, rem(96), rem(0), rem(150), rem(0));
}
.accordion{
  @include ForMarginPadding(padding, rem(10), rem(0), rem(0), rem(0));
  .accordion-item{
    border-width: 0;
    border-bottom-width: 1px;
    @include ForMarginPadding(margin, rem(0),rem(0),rem(15),rem(0));
  }
  .accordion-header{
    @include ForMarginPadding(margin, rem(0),rem(0),rem(0),rem(0));
  }
  .accordion-button{
    @include ForMarginPadding(padding, rem(6), rem(40), rem(6), rem(15));
    @include transitions(0.5s);
    font-size: rem(18);
    &:after{
      content: "\e905";
      position: absolute;
      font-family: 'icomoon' !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      background-image: none;
      @include RightToLeft(rem(10));
      font-size: rem(12);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .accordion-body{
    position: relative;
    @include ForMarginPadding(padding, rem(40), rem(20), rem(30), rem(60));
    &:before{
      content: '';
      position: absolute;
      @include leftToRight(rem(14));
      top: rem(40);
      bottom: rem(40);
      width: rem(5);
      background: $primary;
    }
  }
}




@include for-desktop(){
  .accordion{
    .accordion-button{
     &:hover{
       background-color: $white;
       color: $primary;
     }
    }
  }
}
@include for-phone-portrait(){
  .faq-section{
    @include ForMarginPadding(padding, rem(50), rem(0), rem(40), rem(0));
  }
  .accordion {
    .accordion-body{
      @include ForMarginPadding(padding, rem(40), rem(20), rem(30), rem(15));
      &:before{
        display: none;
      }
    }
  }
}



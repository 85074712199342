.form-group {
  @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

  &.has-validation {
    .form-control {
      border-color: $danger;
    }

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $danger;
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: $danger;
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: $danger;
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: $danger;
    }
  }
}

label {
  font-size: rem(12);
  color: $secondary;
  font-weight: 700;
  @include ForMarginPadding(margin, rem(0), rem(0), rem(7), rem(0));
  display: block;

  &.light {
    color: $white;
  }

  &.height-0 {
    height: 0;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }
}

.form-control {
  @include ForMarginPadding(padding, rem(8), rem(15), rem(8), rem(15));
  width: 100%;
  max-width: 100%;
  font-size: rem(16);
  height: rem(46);
  background-color: $white;
  border: none;

  &:focus {
    background-color: $lightGrey;
    box-shadow: none;
    border-color: $input-border-color;
  }

  &.primary {
    background-color: $white;
  }

  &.secondry {
    @include ForMarginPadding(padding, rem(0), rem(15), rem(8), rem(0));
    height: auto;
    background-color: transparent;
    border-bottom: 1px solid $secondary;
    @include borderRadius(0px);
    font-size: rem(18);
  }

  &.outline {
    background-color: transparent;
    border: 1px solid $secondary;
  }

  &.light {
    background-color: transparent;
    border-color: $white;
    color: $white;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: $white;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $white;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: $white;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: $white;
    }
  }
}

.mandatoryStar {
  color: $danger;
}
.icon-button {
  height: rem(30);
  width: rem(30);
  padding: rem(2);
  box-shadow: none;
  background: none;
  border: none;
  @include borderRadius(50%);

  &:hover {
    background-color: $specialBackground;
  }
}

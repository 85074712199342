.about-us-section-wrapper {
  width: 100%;
  // height: rem(400);
  @include ForMarginPadding(padding, rem(100), rem(0), rem(100), rem(0));
  background: $gray_primary;
  color: $white;
  overflow: hidden;
  position: relative;
  @include transitions(0.5s);
  display: flex;
  align-items: center;

  &:before {
    position: absolute;
    @include leftToRight(0);
    top: 0;
    // width: rem(586);
    width: 100%;
    height: 100%;
    background-image: url("/svgs/lines-pattern.svg");
    background-repeat: repeat-x;
    background-position: center;
    background-size: cover;
    content: "";
  }
  .aboutUsHeading {
    color: $primary-wattone;
    text-align: center;
    font-size: $h1-font-size;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }
      p:first-child {
        color: $gray-600;
        font-weight: normal;
        @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
      }
    }
  }
  .aboutUsSlugan {
    text-transform: uppercase;
    color: $secondary;
    font-size: 10px;
    letter-spacing: 5px;
    text-align: center;
  }

  .aboutUsHeadline {
    color: $primary;
  }

  .aboutUsDescription {
    text-align: justify;
    color: $gray-400;
  }
}

@include for-tablet-landscape() {
  .about-us-section-wrapper {
    .aboutUsHeading {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      font-size: $h2-font-size;
    }
  }
}

@include for-tablet-portrait() {
  .about-us-section-wrapper {
    .aboutUsHeading {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      font-size: $h2-font-size;
    }
  }
}

@include for-phone-landscape() {
  .about-us-section-wrapper {
    .aboutUsHeading {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      font-size: $h3-font-size;
    }
    .aboutUsSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(50), rem(0));
    }
  }
}
@include for-phone-portrait() {
  .about-us-section-wrapper {
    .aboutUsHeading {
      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      font-size: $h3-font-size;
    }
    .aboutUsSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(50), rem(0));
    }
  }
}

/// Relative or absolute URL where all assets are served from
/// @type String
/// @example scss - When using a CDN
///   $baseUrl: '/public/';
$baseUrl: '/';
$fontsPath: '/fonts/';
$imagesPath: '/images/';

/// Fonts To be Imported
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$supportedFontsExts: (
  ('woff2', format('woff2')),
  ('woff', format('woff')),
  ('otf', format('truetype'))
);
$supportedFonts: (GASans, GASans-Heavy, 'GASans', 900),
  (GASans, GASans-Light, 'GASans', 700);

$primaryFontFamily: 'Noto Sans', sans-serif;
$secondaryFontFamily: 'GASans', sans-serif;

$fontFamilyBase: $primaryFontFamily;
$fontFamilyBaseAr: $primaryFontFamily;

$font-family-base: $fontFamilyBase;
$headings-font-family: $secondaryFontFamily;

/// Bootstrap overrides
///
/// Copy variables from `_variables.scss` to this file to override default values
/// without modifying source files.

//Bootstrap Variables
$btn-border-radius: 3px;
$btn-padding-y: 0;
$btn-padding-x: 0;
$btn-border-width: 0;
$btn-box-shadow: none;
$btn-font-size: rem(16);
$btn-font-weight: 400;

$input-border-radius: 5px;
$input-bg: $specialBackground;
$input-color: $secondary;

$input-border-radius: 5px;
$input-border-color: $specialFooter;
$input-border-width: 1px;

$grid-gutter-width: 1.5rem;

$h1-font-size: rem(36);
$h2-font-size: rem(34); //done
$h3-font-size: rem(25);
$h4-font-size: rem(19); // done
$h5-font-size: rem(18); // done
$h6-font-size: rem(16); //done
$lead-font-size: rem(32);
$headings-margin-bottom: 0px;
$headings-font-weight: normal;
$headings-line-height: 1;

$headings-color: inherit;
$font-weight-bolder: 900;

//Card Settings
$card-border-width: none;
$card-spacer-y: 0;
$card-spacer-x: 0;
$card-cap-padding-y: 0;
$card-border-radius: 10px;
$card-bg: none;
$card-color: $secondary;

//Table Settings
$table-cell-padding-y: rem(15);
$table-cell-padding-x: rem(20);
$table-cell-vertical-align: middle;
$table-border-color: $specialFooter;
$table-color: $secondary;

$container-max-widths: (
  xs: 96%,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1156px,
);

//$accordion-border-width: 0;
$accordion-border-color: $grey;
$accordion-color: $black;
$accordion-bg: transparent;
$accordion-border-radius: 0;

$accordion-button-active-bg: $white;
$accordion-button-active-color: $primary;

$accordion-button-focus-border-color: transparent;
$accordion-button-focus-box-shadow: none;

$accordion-icon-transition: transform 0.5s ease-in-out;



/* Badge */
$badge-font-size:                   rem(12);
$badge-font-weight:                 400;
$badge-color:                       $white;
$badge-padding-y:                   0;
$badge-padding-x:                   0;
$badge-border-radius:               0;
/* Badge */
.twoColumnSection{
  position: relative;
  @include ForMarginPadding(padding, rem(0), rem(0), rem(80), rem(0));
  .twoColumnInner{
    position: relative;
    min-height: rem(400);
  }
  .twoColContent{
    @include ForMarginPadding(padding, rem(40), rem(60), rem(40), rem(0));
  }
  .twoColumnImg{
    position: absolute;
    width: 43%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    @include RightToLeft(0);
    top: 0;
  }
  &.left-img{
    .twoColContent{
      @include ForMarginPadding(padding, rem(40), rem(0), rem(40), rem(35));
    }
    .twoColumnImg{
      @include leftToRight(0);
    }
  }
}
.twoBtnWrap{
  .btn{
    @include ForMarginPadding(margin, rem(0), rem(6), rem(0), rem(0));
  }
}

.twoColumnWrapper{
  @include ForMarginPadding(padding, rem(80), rem(0), rem(80), rem(0));
  .twoColumnSection{
    &:last-child{
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    }
  }
  &.grey{
    background-color: $temp-bg;
  }
}


/*.falcon-twoColumn{
  background-color: $temp-bg;
  @include ForMarginPadding(padding, rem(90), rem(0), rem(0), rem(0));
  &+{
    @include ForMarginPadding(padding, rem(90), rem(0), rem(80), rem(0));
  }
}*/




@include for-phone-portrait(){
  .twoColumnSection {
    @include ForMarginPadding(padding, rem(0), rem(0), rem(40), rem(0));
    .twoColumnImg{
      position: relative;
      width: 100%;
      height: rem(300);
    }
    .twoColContent{
      @include ForMarginPadding(padding, rem(40), rem(0), rem(0), rem(0));
    }
    &.left-img{
      .twoColContent{
        @include ForMarginPadding(padding, rem(40), rem(0), rem(0), rem(0));
      }
    }
  }
  .twoColumnWrapper{
    @include ForMarginPadding(padding, rem(40), rem(0), rem(40), rem(0));
  }
}
.lang-search-box {
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  display: flex;
  align-items: center;

  >li {
    list-style: none;
    @include ForMarginPadding(padding, rem(0), rem(25), rem(0), rem(25));

    &:before {
      position: absolute;
      @include RightToLeft(0);
      top: 0;
      width: 1px;
      height: 100%;
      background: rgba($white, 0.2);
      content: '';
    }

    &:first-child {
      @include ForMarginPadding(padding, rem(0), rem(25), rem(0), rem(0));
    }

    &:last-child {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(25));
      position: initial;

      &:before {
        display: none;
      }
    }
  }
}

.selectedLang {
  display: flex;
  align-items: center;
  @include transitions(0.5s);

  span {
    font-size: rem(12);
    color: $white;
    display: flex;
    align-items: center;
    position: relative;
    @include ForMarginPadding(padding, rem(0), rem(5), rem(0), rem(5));
    font-weight: 300;

    b {
      font-weight: 600;
    }

    i {
      @include ForMarginPadding(margin, rem(0), rem(5), rem(0), rem(0));
      @include borderRadius(50%);
      width: rem(18);
      height: rem(18);
    }

    &:before {
      position: absolute;
      @include RightToLeft(0);
      top: 0;
      width: 1px;
      height: 100%;
      background: rgba($white, 0.2);
      content: '';
    }

    &:first-child {
      @include ForMarginPadding(padding, rem(0), rem(7), rem(0), rem(0));
    }

    &:last-child {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(5));

      &:before {
        display: none;
      }
    }
  }
}

.language-selector {
  display: flex;
}

.search-button {
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  color: $white;
  line-height: 1;
  font-weight: 300;
  @include ForMarginPadding(padding, rem(2), rem(0), rem(0), rem(0));
  @include transitions(0.5s);

  span {
    font-size: rem(12);
  }

  i {
    @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
    position: relative;
    top: rem(-2);

    &:before {
      font-size: rem(14);
    }
  }
}


.language-box {
  background: $white;
  @include transitions(0.5s);
}

@include for-desktop() {
  .lang-search-box {
    >li {
      position: relative;
    }
  }

  .language-box {
    position: absolute;
    width: rem(294);
    @include leftToRight(rem(40));
    top: calc(100% + 20px);
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
    z-index: 1;
    @include border-radius(10px);
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 20px);
    filter: drop-shadow(0px 4px 30px rgba(0, 0, 0, 0.25));

    .lang-title {
      font-size: rem(9);
      text-transform: uppercase;
      font-weight: 700;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    }

    .language-select {
      border-bottom: 1px solid $closeButton;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(15), rem(0));

      .css-319lph-ValueContainer {
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      }

      .react-select__placeholder,
      .react-select__single-value,
      .react-select__input-container {
        color: $closeButton;
        font-size: rem(22);
        font-weight: 400;
      }

      .react-select__indicator {
        color: $closeButton;
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

        .css-tj5bde-Svg {
          width: rem(30);
          height: rem(30);
        }
      }

    }

    &:after {
      content: "";
      position: absolute;
      @include RightToLeft(rem(0));
      @include leftToRight(0);
      @include ForMarginPadding(margin, auto, auto, auto, auto);
      top: rem(-13);
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 13px solid rgba($white, 1);
      @include transitions(0.5s);
    }

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 20px);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, 0);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      opacity: 0;
      visibility: hidden;
      transform: translate(-50%, 0);
    }
  }

  .language-selector {
    position: relative;

    &.active {
      .selectedLang {
        background: $primary;
        color: $white !important;
      }
    }
  }

  .selectedLang {
    @include ForMarginPadding(padding, rem(6), rem(12), rem(6), rem(12));
    line-height: 1;
    background: rgba($black, 0.2);
    cursor: pointer;
    @include borderRadius(2px);
  }

  .search-button {
    @include ForMarginPadding(padding, rem(6), rem(12), rem(6), rem(12));
    background: rgba($black, 0.2);
    @include borderRadius(2px);

    &.active {
      background: $primary;
      color: $white !important;
    }
  }
}

@include for-tablet-landscape() {
  .lang-search-box {
    display: flex;
    flex-direction: column-reverse;

    li {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
      width: 100%;

      &:before {
        display: none;
      }

      &:first-child,
      &:last-child {
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      }
    }
  }

  .language-selector {
    display: block;
  }

  .selectedLang {
    background: $grey;
    @include borderRadius(3px);
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

    span {
      color: $secondary;
      font-size: rem(16);
      line-height: 1;

      &:before {
        background: $secondary;
      }
    }
  }

  .search-button {
    display: none;
  }

  .language-box {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}

@include for-tablet-portrait() {
  .lang-search-box {
    display: flex;
    flex-direction: column-reverse;

    li {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
      width: 100%;

      &:before {
        display: none;
      }

      &:first-child,
      &:last-child {
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      }
    }
  }

  .language-selector {
    display: block;
  }

  .selectedLang {
    background: $grey;
    @include borderRadius(3px);
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

    span {
      color: $secondary;
      font-size: rem(16);
      line-height: 1;

      &:before {
        background: $secondary;
      }
    }
  }

  .search-button {
    display: none;
  }

  .language-box {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}

@include for-phone-landscape() {
  .lang-search-box {
    display: flex;
    flex-direction: column-reverse;

    li {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
      width: 100%;

      &:before {
        display: none;
      }

      &:first-child,
      &:last-child {
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      }
    }
  }

  .language-selector {
    display: block;
  }

  .selectedLang {
    background: $grey;
    @include borderRadius(3px);
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

    span {
      color: $secondary;
      font-size: rem(16);
      line-height: 1;

      &:before {
        background: $secondary;
      }
    }
  }

  .search-button {
    display: none;
  }

  .language-box {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}

@include for-phone-portrait() {
  .lang-search-box {
    display: flex;
    flex-direction: column-reverse;

    li {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
      width: 100%;

      &:before {
        display: none;
      }

      &:first-child,
      &:last-child {
        @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      }
    }
  }

  .language-selector {
    display: block;
  }

  .selectedLang {
    background: $grey;
    @include borderRadius(3px);
    @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

    span {
      color: $secondary;
      font-size: rem(16);
      line-height: 1;

      &:before {
        background: $secondary;
      }
    }
  }

  .search-button {
    display: none;
  }

  .language-box {
    position: absolute;
    @include RightToLeft(-100%);
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0%);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
    }
  }
}
.modal-content {
  @include ForMarginPadding(padding, rem(20), rem(30), rem(30), rem(30));

  &.asset-modal {
    background-color: $grey;
  }
  &.new-ugc-modal {
    .scrollable-content {
      height: rem(250);
      @include ForMarginPadding(padding, rem(5), rem(5), rem(5), rem(5));
    }
    .btn-container {
      @include ForMarginPadding(margin, rem(20), rem(0), rem(20), rem(0));
    }
  }
}

.modal-header {
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  border-bottom: none;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }
}

.modal-body {
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }
}

.ugcInnerWrapper {
  @include ForMarginPadding(padding, rem(34), rem(14), rem(14), rem(14));
}

.modal-overlay {
  box-sizing: border-box;
  z-index: 9999;
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  @include leftToRight(0);
  @include RightToLeft(0);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($black, 0.2);

  &.nobackdrop {
    background-color: transparent;
  }

  .modal-box {
    display: block;
    background: $white;
    height: auto;
    width: 94%;
    min-height: rem(350);
    @include ForMarginPadding(margin, auto, 2%, auto, 2%);
    @include borderRadius(rem(10));
    @include ForShadow();
  }

  .modal-xs {
    max-width: rem(576);
  }

  .modal-sm {
    max-width: rem(768);
  }

  .modal-md {
    max-width: rem(992);
  }

  .modal-lg {
    max-width: rem(1200);
  }

  .modal-xl {
    max-width: rem(1499);
  }

  .modal-xxl {
    max-width: rem(1699);
  }
}

.video-modal {
  background-color: $grey;
  @include ForMarginPadding(padding, rem(20), rem(30), rem(30), rem(30));

  .video-modal-header {
    display: flex;
    justify-content: space-between;
  }

  h3 {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  p {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  span {
    cursor: pointer;
  }
}

.ugc-modal {
  padding: rem(16);
  @include borderRadius(rem(10));
  min-height: rem(584);

  .ugc-modal-header {
    display: flex;
    justify-content: flex-end;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));
  }

  .btn-container {
    @include ForMarginPadding(margin, rem(10), rem(0), rem(10), rem(0));
  }

  .scrollable-content {
    height: rem(250);
    @include ForMarginPadding(padding, rem(5), rem(5), rem(5), rem(5));
  }

  h3 {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  p {
    font-size: rem(15);
    @include ForMarginPadding(margin, rem(0), rem(0), rem(25), rem(0));
  }

  span {
    cursor: pointer;
  }
}

@include for-tablet-landscape() {
  .ugc-modal {
    min-height: rem(500);

    .ugc-modal-header {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
    }

    .btn-container {
      button {
        width: 100%;
        @include ForMarginPadding(margin, rem(5), rem(0), rem(0), rem(0));
      }
    }

    .scrollable-content {
      height: rem(250);
      @include ForMarginPadding(padding, rem(5), rem(5), rem(5), rem(5));
    }
  }
}

@include for-tablet-portrait() {
  .ugc-modal {
    min-height: rem(500);

    .ugc-modal-header {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
    }

    .btn-container {
      button {
        width: 100%;
        @include ForMarginPadding(margin, rem(5), rem(0), rem(0), rem(0));
      }
    }

    .scrollable-content {
      height: rem(250);
      @include ForMarginPadding(padding, rem(5), rem(5), rem(5), rem(5));
    }
  }
}

@include for-phone-landscape() {
  .ugc-modal {
    min-height: rem(500);

    .ugc-modal-header {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
    }

    .btn-container {
      button {
        width: 100%;
        @include ForMarginPadding(margin, rem(5), rem(0), rem(0), rem(0));
      }
    }

    .scrollable-content {
      height: rem(200);
      @include ForMarginPadding(padding, rem(5), rem(5), rem(5), rem(5));
    }
  }
}

@include for-phone-portrait() {
  .ugc-modal {
    min-height: rem(500);

    .ugc-modal-header {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
    }

    .btn-container {
      button {
        width: 100%;
        @include ForMarginPadding(margin, rem(5), rem(0), rem(0), rem(0));
      }
    }

    .scrollable-content {
      height: rem(200);
      @include ForMarginPadding(padding, rem(5), rem(5), rem(5), rem(5));
    }
  }
}

.bannerSection {
  position: relative;
  background: $secondary;
  height: 100vh;
  // height: rem(650);

  &.inner {
    height: rem(750);
  }
  .sliderHeading {
    font-size: $h1-font-size;
    color: $primary;
  }
  .sliderDescription {
    color: $white;
  }
}

.hero-main-slider {
  // height: 100vh;
  height: 100%;
  position: relative;
  overflow: hidden;

  .slick-slider,
  .slick-list,
  .slick-slide,
  .slick-track {
    height: 100%;
  }

  .slide {
    .slide-asset {
      position: absolute;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &:before {
        content: "";
        position: absolute;
        @include leftToRight(0);
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(
            180deg,
            rgba(20, 20, 31, 0) 57.81%,
            #14141f 100%
          ),
          linear-gradient(
            360deg,
            rgba(20, 20, 31, 0) 67.84%,
            rgba(20, 20, 31, 0.7) 100%
          ),
          linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
        background-blend-mode: normal, multiply, normal, normal;
      }
    }
  }

  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    top: 0;
    @include leftToRight(0);
    z-index: 2;
    @include ForMarginPadding(padding, rem(240), rem(0), rem(0), rem(0));

    h1 {
      @include ForTextShadow("large");
      @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));
    }

    a,
    button {
      @include ForMarginPadding(margin, rem(10), rem(0), rem(0), rem(0));
    }
  }

  .slider-dots-wrapper {
    width: 100%;
    position: absolute;
    top: rem(450);
    @include leftToRight(0%);
    z-index: 2;
  }

  .slider-dots {
    list-style: none;
    display: flex;
    align-items: center;
    color: $white;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    height: rem(28);

    li {
      height: 100%;
      display: flex;
      align-items: center;
      @include ForMarginPadding(margin, rem(0), rem(8), rem(0), rem(8));
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      position: relative;

      &:before {
        position: absolute;
        @include RightToLeft(0);
        top: 0;
        width: 1px;
        height: 100%;
        background: $white;
        display: none;
        content: "";
      }

      &:first-child {
        @include ForMarginPadding(padding, rem(0), rem(20), rem(0), rem(0));

        &:before {
          display: block;
        }

        @include ForMarginPadding(margin, rem(0), rem(8), rem(0), rem(0));
      }

      button {
        background: none;
        border: none;
        color: $white;
      }

      i {
        @include ForTextShadow("large");

        &:before {
          font-size: rem(10);
        }
      }

      span {
        height: rem(11);
        width: rem(11);
        @include borderRadius(50%);
        background: $white;
        border: 3px solid $white;
        @include transitions(0.5s);
        cursor: pointer;
        @include ForShadow("large");
      }

      &.active {
        span {
          background: none;
        }
      }
    }
  }

  .temp-box {
    background: $temp-bg;
    width: rem(350);
    @include border-radius(rem(10));
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), auto);

    ul {
      list-style: none;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $secondary;
      }
    }
  }
}


.contentPlacement {
  display: inline-block;
}

@include for-tablet-landscape() {
  .bannerSection {
    height: 100vh;
  }
  .hero-main-slider {
    .content {
      width: 100%;
      height: auto;
      @include leftToRight(0);
      @include ForMarginPadding(padding, rem(285), rem(0), rem(0), rem(0));
      .sliderHeading {
        font-size: $h2-font-size;
      }
      .sliderDescription {
        font-size: rem(16);
      }
    }
    .slider-dots-wrapper {
      top: rem(450);
    }
  }
}

@include for-tablet-portrait() {
  .bannerSection {
    height: 100vh;
  }
  .hero-main-slider {
    .content {
      width: 100%;
      height: auto;
      @include leftToRight(0);
      @include ForMarginPadding(padding, rem(255), rem(0), rem(0), rem(0));
      .sliderHeading {
        font-size: $h2-font-size;
        @include ForDirection(text-align, left, left);
      }
      .sliderDescription {
        font-size: rem(16);
      }
    }
    .slider-dots-wrapper {
      top: rem(415);
    }
  }
}

@include for-phone-landscape() {
  .bannerSection {
    height: initial;
  }

  .hero-main-slider {
    height: rem(450);

    .content {
      width: 100%;
      height: auto;
      @include leftToRight(0);
      @include ForMarginPadding(padding, rem(120), rem(0), rem(0), rem(0));
      .sliderHeading {
        font-size: $h3-font-size;
        @include ForDirection(text-align, left, left);
      }
      .sliderDescription {
        @include ForDirection(text-align, justify, justify);
        font-size: rem(12);
      }
    }

    .sliderData {
      // @include ForDirection(text-align, center, center);
      @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
      background: rgba(112, 112, 112, 0.6);
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.24);
      backdrop-filter: blur(2.5px);
      @include borderRadius(10px);

      .btn {
        width: 100%;
        display: block;
      }
    }

    .slider-dots-wrapper {
      top: initial;
      bottom: rem(30);
    }

    .slider-dots {
      justify-content: center;
    }

    .temp-box {
      width: 100%;
      @include ForMarginPadding(margin, rem(20), auto, auto, auto);
    }
  }
}

@include for-phone-portrait() {
  .bannerSection {
    height: initial;
  }

  .hero-main-slider {
    height: rem(450);

    .content {
      width: 100%;
      height: auto;
      @include leftToRight(0);
      @include ForMarginPadding(padding, rem(120), rem(0), rem(0), rem(0));
      .sliderHeading {
        font-size: $h3-font-size;
        @include ForDirection(text-align, left, left);
      }
      .sliderDescription {
        @include ForDirection(text-align, justify, justify);
        font-size: rem(12);
      }
    }

    .sliderData {
      // @include ForDirection(text-align, left, left);
      @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
      background: rgba(112, 112, 112, 0.6);
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.24);
      backdrop-filter: blur(2.5px);
      @include borderRadius(10px);

      .btn {
        width: 100%;
        display: block;
      }
    }

    .slider-dots-wrapper {
      top: initial;
      bottom: rem(30);
    }

    .slider-dots {
      justify-content: center;
    }

    .temp-box {
      width: 100%;
      @include ForMarginPadding(margin, rem(20), auto, auto, auto);
    }
  }
}

.sideBarLayout{
  background-color: $specialBackground;
  @include ForMarginPadding(padding, rem(85),rem(0),rem(85),rem(0));
}

.print-bar{
  display: flex;
  justify-content: flex-end;
  @include ForMarginPadding(padding, rem(10),rem(0),rem(10),rem(0));
}

.sideBarNav{
  background-color: $white;
  box-shadow: 0 rem(4) rem(30) rgba($black, 0.25);
  @include ForMarginPadding(padding, rem(10),rem(20),rem(10),rem(20));
  @include border-radius(rem(10));
  width: 81%;
  ul{
    list-style: none;
    @include ForMarginPadding(padding, rem(0),rem(0),rem(0),rem(0));
    @include ForMarginPadding(margin, rem(0),rem(0),rem(0),rem(0));
    width: 100%;
    li{
      display: block;
      a{
        display: block;
        @include ForMarginPadding(padding, rem(10),rem(16),rem(10),rem(16));
        color: $secondary;
        @include transitions(0.5s);
        text-decoration: none;
      }
    }
  }
}


@include for-desktop(){
  .sideBarNav{
    ul{
      li{
        a{
          &:hover{
            background-color: $primary;
            color: $white;
          }
        }
      }
    }
  }
}


@include for-tablet-portrait(){
  .sideBarNav{
    width: 96%;
  }
}

@include for-phone-portrait(){
  .sideBarNav{
    width: 100%;
    @include ForMarginPadding(margin, rem(0),rem(0),rem(40),rem(0));
  }
  .print-bar{
    justify-content: center;
    @include ForMarginPadding(padding, rem(10),rem(0),rem(20),rem(0));
  }
}
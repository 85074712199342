.loginButtonsList {
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  @include ForMarginPadding(margin, rem(0), rem(25), rem(0), rem(0));
  list-style: none;
  display: flex;
  align-items: center;
  column-gap: rem(5);
  justify-content: space-between;

  >li {
    &:last-child {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    }

    >a {
      background: $secondary;
      color: $white;
      text-decoration: none;
      display: flex;
      align-items: center;
      @include borderRadius(3px);
      @include ForMarginPadding(padding, rem(6), rem(12), rem(6), rem(12));
      line-height: 1;
      font-weight: 300;
      @include transitions(0.5s);

      span {
        font-size: rem(12);
      }

      i {
        @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
        position: relative;
        top: rem(-2);

        &:before {
          font-size: rem(12);
        }
      }

      &:hover {
        text-decoration: none;
      }

      &.flyer-login {
        background-color: $primary;
      }
    }
  }
}

@include for-desktop() {
  .loginButtonsList {
    >li {
      position: relative;

      >a {

        &:hover,
        &.active {
          background: $primary;
          color: $white;
        }
      }
    }
  }
}

@include for-tablet-landscape() {
  .loginButtonsList {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    >li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      width: 50%;

      >a {
        @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

        span {
          font-size: rem(16);
          line-height: 1;
        }
      }
    }
  }
}

@include for-tablet-portrait() {
  .loginButtonsList {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    >li {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      width: 50%;

      >a {
        @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

        span {
          font-size: rem(16);
          line-height: 1;
        }
      }
    }
  }
}

@include for-phone-landscape() {
  .loginButtonsList {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    li {
      width: 50%;

      >a {
        @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

        span {
          font-size: rem(16);
          line-height: 1;
        }
      }
    }
  }
}

@include for-phone-portrait() {
  .loginButtonsList {
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    li {
      width: 50%;

      >a {
        @include ForMarginPadding(padding, rem(10), rem(10), rem(10), rem(10));

        span {
          font-size: rem(16);
          line-height: 1;
        }
      }
    }
  }
}
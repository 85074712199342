.checkbox-item-label {
  display: flex;
  font-size: rem(17);
  position: relative;
  top: rem(5);

  span {
    padding-top: rem(1);
    line-height: 1.3;
    color: $secondary;
  }

  &.has-error {
    .checkbox-item i:before,
    .checkbox-item em:before {
      border-color: $danger;
    }
  }

  .checkbox-item {
    position: relative;
    @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
    padding-top: 0;

    input {
      position: absolute;
      top: 0;
      @include leftToRight(0);
      opacity: 0;

      &:checked {
        & + i,
        & + em {
          &:after {
            opacity: 1;
          }
        }
      }
    }

    i,
    em {
      width: rem(23);
      height: rem(23);
      position: relative;
      text-align: center;
      display: inline-block;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        @include leftToRight(0);
        background: $specialBackground;
        border: 1px solid $specialFooter;
        @include border-radius(5px);
        opacity: 1;
        height: rem(23);
        width: rem(23);
      }

      &:after {
        content: "\e903";
        font-family: 'icomoon';
        font-style: normal !important;
        font-weight: normal !important;
        font-variant: normal !important;
        text-transform: none !important;
        position: absolute;
        @include leftToRight(0);
        @include RightToLeft(0);
        @include ForMarginPadding(margin, auto, auto, auto, auto);
        line-height: rem(23);
        color: $secondary;
        opacity: 0;
        font-size: rem(10);
      }
    }
  }
}


.form-check{
  display: flex;
  align-items: flex-start;
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(24));
  .form-check-input {
    @include borderRadius(50%);
    border: solid 1px $lightGrey;
    background: $white;
    @include transitions(0.5s);
    position: relative;
    top: -1px;
    @include ForMarginPadding(margin, rem(4), rem(0), rem(0), rem(-24));
    &:checked[type=radio] {
      background-image: none;
      background-color: $secondary;
      border-color: $secondary;
    }
    &:focus {
      box-shadow: none;
    }
  }
  label {
    font-size: rem(14);
    font-weight: 400;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(10));
  }
  + .form-check{
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(20));
  }
}
@font-face {
  font-family: 'icomoon';
  src: url('../../../public/fonts/icomoon/icomoon.eot?vmc0pa');
  src: url('../../../public/fonts/icomoon/icomoon.eot?vmc0pa#iefix') format('embedded-opentype'),
  url('../../../public/fonts/icomoon/icomoon.woff2?vmc0pa') format('woff2'),
  url('../../../public/fonts/icomoon/icomoon.ttf?vmc0pa') format('truetype'),
  url('../../../public/fonts/icomoon/icomoon.woff?vmc0pa') format('woff'),
  url('../../../public/fonts/icomoon/icomoon.svg?vmc0pa#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-aeroplane-land:before {
  content: "\e91f";
}
.icon-left-arrow:before {
  content: "\e91d";
}
.icon-right-arrow:before {
  content: "\e91e";
}
.icon-aeroplane:before {
  content: "\e91c";
}
.icon-location-icon:before {
  content: "\e91b";
}
.icon-dirction-arrows:before {
  content: "\e91a";
}
.icon-alert:before {
  content: "\e919";
}
.icon-cross:before {
  content: "\e918";
}
.icon-solid-close:before {
  content: "\e917";
}
.icon-close-icon:before {
  content: "\e916";
}
.icon-arrow-left:before {
  content: "\e915";
}
.icon-search:before {
  content: "\e914";
}
.icon-user:before {
  content: "\e913";
}
.icon-linkedin:before {
  content: "\e911";
}
.icon-youtube:before {
  content: "\e90e";
}
.icon-facebook:before {
  content: "\e90f";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-twitter:before {
  content: "\e912";
}
.icon-pause:before {
  content: "\e90d";
}
.icon-arrow-down:before {
  content: "\e900";
}
.icon-arrow-right:before {
  content: "\e901";
}
.icon-checkmark:before {
  content: "\e902";
}
.icon-close:before {
  content: "\e903";
}
.icon-download:before {
  content: "\e904";
}
.icon-dropdown-down:before {
  content: "\e905";
}
.icon-dropdown-up:before {
  content: "\e906";
}
.icon-heart:before {
  content: "\e907";
}
.icon-link:before {
  content: "\e908";
}
.icon-play:before {
  content: "\e909";
}
.icon-question:before {
  content: "\e90a";
}
.icon-share:before {
  content: "\e90b";
}
.icon-star:before {
  content: "\e90c";
}


/*RTL CSS*/

[dir='rtl']{
  .icon-arrow-left:before {
    content: "\e901";
  }
  .icon-arrow-right:before {
    content: "\e915";
  }
}

/// Start Of Website Color Branding
/* brand foundation */

$primary: #F3C512;
$secondary: #12679B;
$gray_primary: #191817;
$gray_secondary: #303030;
$grey: #e3dedb;
$white: #ffffff;
$black: #000000;
$mediumGrey: #717172;
$lightGrey: #a0a0a1;
$neutralWarm: #362636;
$neutralCool: #121214;
$specialFooter: #1e1e2e;
$specialBackground: #f3f3f3;
$gold: #b39b6d;
$teal: #00a09c;
$bookingSystemTabs: #352636;
$bookingContentTabs: #fcfcfd;
$closeButton: #333333;
$playButton: #777e90;
$highContrastTeal: #00b0ab;
$highContrastOrange: #fc754f;
$tint: #e3dfdc;
$temp-bg: #dbdbdb;
$transparent: transparent;

$primary-wattone: #F3C512;
$secondary-wattone: #12679B;

// GREY 
$gray-0: #FFFFFF;
$gray-100: #F9FAFB;
$gray-200: #F4F6F8;
$gray-300: #DFE3E8;
$gray-400: #C4CDD5;
$gray-500: #919EAB;
$gray-600: #637381;
$gray-700: #454F5B;
$gray-800: #212B36;
$gray-900: #161C24;


// PRIMARY 
$primary-lighter: #D1E9FC;
$primary-light: #76B0F1;
$primary-main: #2065D1;
$primary-dark: #103996;
$primary-darker: #061B64;
$primary-contrastText: #fff;

// SECONDARY 
$secondary-lighter: #D6E4FF;
$secondary-light: #84A9FF;
$secondary-main: #3366FF;
$secondary-dark: #1939B7;
$secondary-darker: #091A7A;
$secondary-contrastText: #fff;


// INFO
$info-lighter: #D0F2FF;
$info-light: #74CAFF;
$info-main: #1890FF;
$info-dark: #0C53B7;
$info-darker: #04297A;
$info-contrastText: #fff;

//SUCCESS
$success-lighter: #E9FCD4;
$success-light: #AAF27F;
$success-main: #54D62C;
$success-dark: #229A16;
$success-darker: #08660D;
$success-contrastText: #212B36;


// WARNING
$warning-lighter: #FFF7CD;
$warning-light: #FFE16A;
$warning-main: #FFC107;
$warning-dark: #B78103;
$warning-darker: #7A4F01;
$warning-contrastText: #212B36;

// ERROR
$error-lighter: #FFE7D9;
$error-light: #FFA48D;
$error-main: #FF4842;
$error-dark: #B72136;
$error-darker: #7A0C2E;
$error-contrastText: #fff;

/// begin customization
$theme-colors: (
);

/// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(('primary': $primary,
                        'secondary': $secondary,
                        'grey': $grey,
                        'white': $white,
                        'black': $black,
                        'mediumGrey': $mediumGrey,
                        'lightGrey': $lightGrey,
                        'neutralWarm': $neutralWarm,
                        'neutralCool': $neutralCool,
                        'special-footer': $specialFooter,
                        'special-background': $specialBackground,
                        'gold': $gold,
                        'teal': $teal,
                        'booking-system-tabs': $bookingSystemTabs,
                        'close-button': $closeButton,
                        'play-button': $playButton,
                        'high-contrast-teal': $highContrastTeal,
                        'highContrastOrange': $highContrastOrange,
                        'tint': $tint,
                        'transparent': $transparent,
                        // 'primary-wattone':$primary-wattone,
                        // 'gray-0': $gray-0 , 
                        // 'gray-100': $gray-100,
                        // 'gray-200': $gray-200,
                        // 'gray-300': $gray-300,
                        // 'gray-400': $gray-400,
                        // 'gray-500': $gray-500,
                        // 'gray-600': $gray-600,
                        // 'gray-700': $gray-700,
                        // 'gray-800': $gray-800,
                        // 'gray-900': $gray-900,

                        // 'primary-lighter':$primary-lighter,
                        // 'primary-light':$primary-light,
                        // 'primary-main ':$primary-main ,
                        // 'primary-dark ':$primary-dark ,
                        // 'primary-darker':$primary-darker,
                        // 'primary-contrastText':$primary-contrastText,

                        // 'secondary-lighter':$secondary-lighter,
                        // 'secondary-light':$secondary-light,
                        // 'secondary-main':$secondary-main,
                        // 'secondary-dark':$secondary-dark,
                        // 'secondary-darker':$secondary-darker,
                        // 'secondary-contrastText':$secondary-contrastText,


                        // 'info-lighter':$info-lighter,
                        // 'info-light':$info-light,
                        // 'info-main':$info-main,
                        // 'info-dark':$info-dark,
                        // 'info-darker':$info-darker,
                        // 'info-contrastText':$info-contrastText,

                        // 'success-lighter':$success-lighter,
                        // 'success-light':$success-light,
                        // 'success-main':$success-main,
                        // 'success-dark':$success-dark,
                        // 'success-darker':$success-darker,
                        // 'success-contrastText':$success-contrastText,

                        // 'warning-lighter':$warning-lighter,
                        // 'warning-light':$warning-light,
                        // 'warning-main':$warning-main,
                        // 'warning-dark':$warning-dark,
                        // 'warning-darker':$warning-darker,
                        // 'warning-contrastText':$warning-contrastText,

                        // 'error-lighter':$error-lighter,
                        // 'error-light':$error-light,
                        // 'error-main':$error-main,
                        // 'error-dark':$error-dark,
                        // 'error-darker':$error-darker,
                        // 'error-contrastText':$error-contrastText ),
                ), $theme-colors);
.temperature-sec{
  @include ForMarginPadding(padding, rem(50),rem(0),rem(50),rem(0));
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  .temperature-inner{
    background-color:rgba($white,0.8) ;
    @include ForMarginPadding(padding, rem(50),rem(50),rem(50),rem(50));
    @include border-radius(rem(10));
    display: flex;
    align-items: center;
    .temperature-content{
      width: 30%;
      @include ForMarginPadding(padding, rem(0),rem(20),rem(0),rem(0));
    }
    .temperature-img{
      width: 70%;
    }
  }
  .mobile-drag{
    display: none;
  }
  .drag-to-view{
    @include ForMarginPadding(margin, rem(35),rem(0),rem(0),rem(0));
  }
}

.drag-to-view{
  display: flex;
  align-items: center;
  span{
    @include ForMarginPadding(padding, rem(0),rem(14),rem(0),rem(14));
    font-size: rem(11);
    font-weight: 700;
    text-transform: uppercase;
  }
  i{
    &:before{
      font-size: rem(14);
    }
  }
}


@include for-tablet-landscape(){
  .temperature-sec{
    .temperature-inner{
      .temperature-content{
        width: 35%;
      }
      .temperature-img{
        width: 65%;
      }
    }
  }
}

@include for-tablet-portrait(){
  .temperature-sec{
    .temperature-inner{
      .temperature-content{
        width: 40%;
      }
      .temperature-img{
        width: 60%;
      }
    }
  }
}

@include for-phone-portrait(){
  .temperature-sec{
    .temperature-inner{
      flex-direction: column;
      @include ForMarginPadding(padding, rem(40),rem(20),rem(50),rem(20));
      .temperature-content{
        @include ForMarginPadding(padding, rem(0),rem(0),rem(25),rem(0));
        width: 100%;
      }
      .temperature-img{
        width: 100%;
        overflow-y: auto;
        @include ForMarginPadding(padding, rem(0),rem(10),rem(10),rem(0));
        img{
          width: auto;
          max-width: none;
        }
      }
    }
    .mobile-drag{
      display: block;
    }
  }
}
@each $color, $value in $theme-colors {
  .card-#{$color} {
    .card-bottom-content {
      background: $value;
    }

    @if $color == "transparent" {
      .card-body {
        color: $white;
      }
    } @else if $color == "white" {
      .card-body {
        color: $secondary;
      }
    } @else if $color == "grey" {
      .card-body {
        color: $secondary;
      }
    } @else {
      .card-body {
        color: $white;
      }
    }
  }
}

.card {
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  width: 100%;

  &.xl {
    height: rem(738);
  }

  &.lg {
    height: rem(558);
  }

  &.md {
    height: rem(400);
  }

  &.sm {
    height: rem(354);
  }

  &.xs {
    height: rem(270);
  }

  .card-body {
    z-index: 2;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    transition: none;

    h1, h2, h3, h4, h5, h6 {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }
    }

    p {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(5), rem(0));

      &:last-child {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }
    }
  }
}

.cardIcon {
  width: rem(94);
  height: rem(84);
  display: block;
  @include ForMarginPadding(margin, rem(0), auto, rem(42), auto);
}

.card-top-content {
  @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
  display: flex;
}

.card-bottom-content {
  @include ForMarginPadding(margin, auto, rem(0), rem(0), rem(0));
  @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
  position: relative;
}

.card-tags {
  ul {
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    display: flex;
    justify-content: flex-start;

    li {
      list-style: none;
      @include ForMarginPadding(margin, rem(0), rem(5), rem(0), rem(5));

      &:first-child {
        @include ForMarginPadding(margin, rem(0), rem(5), rem(0), rem(0));
      }
    }
  }
}

.card-img-bx {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  @include transition(0.5s);
  position: absolute;
  width: 100%;
  height: 100%;
  @include leftToRight(0);
  bottom: 0;

  &:before {
    position: absolute;
    @include leftToRight(0);
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    linear-gradient(
                    180deg,
                    rgba(20, 20, 31, 0) 50%,
                    rgba(20, 20, 31, 0.7) 100%
    );
    content: '';
  }
}

.card-tag {
  @include ForMarginPadding(padding, rem(11), rem(20), rem(11), rem(20));
  background: rgba($grey, 0.8);
  text-align: center;
  @include borderRadius(3px);
  z-index: 2;
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), auto);
}

.card-arrow {
  position: absolute;
  @include RightToLeft(rem(25));
  bottom: rem(25);
  height: rem(20);
  @include ForMarginPadding(margin, auto, rem(0), auto, rem(0));

  &:before {
    font-size: rem(16);
  }
}

.card-social-bx {
  width: 100%;
  height: rem(40);
  display: flex;
  justify-content: flex-end;

  nav {
    ul {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
      display: flex;
      @include borderRadius(3px);
      overflow: hidden;

      li {
        list-style: none;
        width: rem(40);
        height: rem(40);

        a {
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba($white, 0.8);
          color: $secondary;
          width: 100%;
          height: 100%;
          @include transitions(0.5s);
          text-decoration: none;

          i {
            &:before {
              font-size: rem(20);
              font-weight: 600;
            }
          }

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}

.fullLink {
  position: absolute;
  top: 0;
  @include leftToRight(0);
  width: 100%;
  height: 100%;
  text-indent: -9000px;
}

.share-detail-bx {
  display: flex;
  align-items: center;

  .share-img {
    width: rem(45);
    height: rem(45);
    @include borderRadius(50%);
    border: 2px solid $white;
    overflow: hidden;
    @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));

    img {
      width: 100%;
    }
  }

  .share-txt {
    display: flex;
    flex-direction: column;

    span {
      line-height: 1.2;
    }
  }
}

.card-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: rem(10);

  .btn {
    width: 48%;
    min-width: 48%;
    justify-content: center;
    @include ForMarginPadding(padding, rem(11), rem(0), rem(11), rem(0));
  }
}

.price-bx {
  display: block;
  line-height: 1;
  @include ForMarginPadding(margin, rem(0), rem(0), rem(10), rem(0));

  strong,
  b {
    font-weight: 700;
    font-size: rem(30);
  }
}

.taq-list {
  list-style: none;
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

  li {
    display: inline-block;
    position: relative;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(22));
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(22));

    &::before {
      position: absolute;
      content: '';
      @include leftToRight(rem(-3));
      width: rem(6);
      height: rem(6);
      top: 0;
      bottom: 0;
      @include ForMarginPadding(margin, auto, rem(0), auto, rem(0));
      @include borderRadius(50%);
      background-color: $white;
    }

    &:first-child {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

      &::before {
        display: none;
      }
    }
  }
}

// Extra large devices (large desktops, 1200px and up)
@include for-desktop() {
  .card {
    &:hover {
      .card-img-bx {
        @include transform(scale(1.04));

        &:before {
          opacity: 1;
        }
      }
    }
  }
  .card-social-bx {
    nav {
      ul {
        li {
          a {
            &:hover {
              background: $teal;
              color: $white;
            }
          }
        }
      }
    }
  }
}

@include for-tablet-portrait() {
  .card {
    &.xl {
      height: rem(590);
    }

    &.lg {
      height: rem(590);
    }

    &.md {
      height: rem(354);
    }

    &.sm {
      height: rem(280);
    }

    &.xs {
      height: rem(280);
    }
  }
  .card-top-content {
    @include ForMarginPadding(padding, rem(20), rem(20), rem(0), rem(20));
  }
  .card-bottom-content {
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
  }
  .price-bx {
    strong,
    b {
      font-size: rem(20);
    }
  }
  .card-arrow {
    @include RightToLeft(rem(20));
    bottom: rem(20);

    &:before {
      font-size: rem(14);
    }
  }
}

@include for-phone-landscape() {
  .card {
    &.lg {
      height: rem(738);
    }

    &.md {
      height: rem(420);
    }

    &.sm {
      height: rem(354);
    }

    &.xs {
      height: rem(185);
    }
  }
  .card-top-content {
    @include ForMarginPadding(padding, rem(20), rem(20), rem(0), rem(20));
  }
  .card-bottom-content {
    @include ForMarginPadding(padding, rem(0), rem(20), rem(20), rem(20));
  }
}

@include for-phone-portrait() {
  .card {
    &.lg {
      height: rem(403);
    }

    &.md {
      height: rem(420);
    }

    &.sm {
      height: rem(354);
    }

    &.xs {
      height: rem(185);
    }
  }
  .card-top-content {
    @include ForMarginPadding(padding, rem(20), rem(20), rem(0), rem(20));
  }
  .card-bottom-content {
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
  }
  .price-bx {
    strong,
    b {
      font-size: rem(20);
    }
  }
  .card-arrow {
    @include RightToLeft(rem(20));
    bottom: rem(20);

    &:before {
      font-size: rem(14);
    }
  }
}

html {
  font-size: rem(16);
}

body {
  @include fontFamily();
  background-color: $white;
  color: $secondary;
  font-weight: 400;
}

* {
  outline: none;
}

a {
  cursor: pointer;
}

p {
  margin: 0;
}

.mainApp{
  @include ForDirection(text-align, left, right);
}


/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var($white) inset !important;
  -webkit-text-fill-color: var($secondary) !important;
}


@include for-tablet-landscape() {

}
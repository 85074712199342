.video-box {
  width: 100%;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
  }
  &.border-radius {
    @include borderRadius(rem(10));
  }
}

.htmlVideoPlayer {
  width: 100%;
}

.video-link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  @include leftToRight(0);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    @include leftToRight(0);
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($black, 0.2);
    @include transitions(0.5s);
    opacity: 0;
  }
}

.video-icon-primary {
  width: rem(80);
  height: rem(80);
  position: absolute;
  top: 0;
  @include leftToRight(0);
  @include RightToLeft(0);
  bottom: 0;
  @include ForMarginPadding(margin, auto, auto, auto, auto);
  background: #fcfcfd;
  @include borderRadius(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  @include transitions(0.5s);

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border: 1px solid $grey;
    @include transform(scale(1));
    opacity: 0;
    @include borderRadius(50%);
    @include transitions(0.5s);
  }

  i {
    font-size: rem(20);
    color: $mediumGrey;
  }
}

.video-icon-secondary {
  width: rem(80);
  height: rem(80);
  position: relative;
  z-index: 99;
  top: 0;
  @include leftToRight(0);
  @include RightToLeft(0);
  bottom: 0;
  @include ForMarginPadding(margin, auto, auto, auto, auto);
  background: transparent;
  @include borderRadius(50%);
  display: flex;
  justify-content: center;
  align-items: center;
  @include transitions(0.5s);

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border: none;
    background: $mediumGrey;
    @include transform(scale(0));
    opacity: 0;
    @include borderRadius(50%);
    @include transitions(0.5s);
  }

  i {
    font-size: rem(25);
    color: $white;
    z-index: 999;
  }
}

@include for-desktop() {
  .video-link {
    &:hover {
      &:before {
        opacity: 1;
      }

      .video-icon-primary {
        &:before {
          opacity: 1;
          @include transform(scale(1.1));
        }
      }

      .video-icon-secondary {
        &:before {
          opacity: 1;
          @include transform(scale(1));
        }
      }
    }
  }
}

@include for-tablet-landscape() {
  .video-icon-primary {
    width: rem(64);
    height: rem(64);
  }
}

@include for-tablet-portrait() {
  .video-icon-primary {
    width: rem(64);
    height: rem(64);
  }
}

@include for-phone-landscape() {
  .video-icon-primary {
    width: rem(64);
    height: rem(64);

    i {
      font-size: rem(18);
    }
  }
}

@include for-phone-portrait() {
  .video-icon-primary {
    width: rem(64);
    height: rem(64);

    i {
      font-size: rem(18);
    }
  }
}

.badge{
  display: block;
  @include ForMarginPadding(padding, rem(13), rem(20), rem(13), rem(20));
}

@each $color, $value in $theme-colors {
  .text-bg-#{$color} {
    color: $white !important;
    background: $value;
    @if $color == "white" {
      color: $secondary !important;
    }
  }
}
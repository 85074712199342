@each $color, $value in $theme-colors {
  .videoWithContent-#{$color} {
    background: $value;
    position: relative;
    @if $color == 'white' {
      .withVideoContent {
        &-center {
          color: $secondary;
        }
      }
    }
  }
}

.withVideoContent {
  height: 100%;
  @include ForMarginPadding(padding, rem(0), rem(30), rem(0), rem(0));

  &-center {
    height: 100%;
    @include ForMarginPadding(padding, rem(15), rem(80), rem(15), rem(80));
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $white;
    @include ForDirection(text-align, center, center);
  }
}

.btn-center-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  column-gap: rem(20);
  @include ForMarginPadding(margin, rem(30), rem(0), rem(0), rem(0));

  .btn {
    width: 100%;
  }
}

.list-dot {
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(18));
}

.preFlightSection {
  @include ForMarginPadding(padding, rem(100), rem(0), rem(100), rem(0));
}

@include for-phone-landscape() {
  .videoWithContent {
    .promotion-content {
      @include ForMarginPadding(padding, rem(20), rem(10), rem(20), rem(10));
    }
  }
}

@include for-tablet-portrait() {
  .videoWithContent {
    .promotion-content {
      @include ForMarginPadding(padding, rem(20), rem(50), rem(20), rem(50));
    }
  }
}

@include for-phone-landscape() {
  .videoWithContent {
    .promotion-content {
      @include ForMarginPadding(padding, rem(35), rem(20), rem(35), rem(20));
    }
  }
}

@include for-phone-portrait() {
  .preFlightSection {
    @include ForMarginPadding(padding, rem(40), rem(0), rem(40), rem(0));
  }
  .withVideoContent {
    @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));

    &-center {
      @include ForMarginPadding(padding, rem(44), rem(25), rem(44), rem(25));
    }
  }
  .btn-center-wrap {
    flex-direction: column;
    column-gap: initial;

    .btn {
      + .btn {
        @include ForMarginPadding(margin, rem(15), rem(0), rem(0), rem(0));
      }
    }
  }
}

.topSearchBox {
  position: absolute;
  @include RightToLeft(0);
  @include leftToRight(0);
  top: 100%;
  z-index: 1;
  @include transitions(0.5s);
  visibility: hidden;
  opacity: 0;
  transform: translate(0, 20px);

  &.active-appear-done,
  &.active-appear-active,
  &.active-appear {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 20px);
  }


  &.active-enter,
  &.active-enter-active,
  &.active-enter-done {
    opacity: 1;
    visibility: visible;
    transform: translate(0, 0);
  }

  &.active-exit,
  &.active-exit-active,
  &.active-exit-done {
    opacity: 0;
    visibility: hidden;
    transform: translate(0, 20px);
  }
}

.innerSearchBox {
  background-color: $white;
  @include ForMarginPadding(padding, rem(14), rem(60), rem(20), rem(22));
  @include borderRadius(10px);
  position: relative;

  .search-title {
    font-size: rem(9);
    text-transform: uppercase;
    color: $closeButton;
    font-weight: 700;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  .search-fields {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-group {
    width: 100%;
    @include ForMarginPadding(margin, rem(0), rem(20), rem(0), rem(0));
  }

  .form-control {
    border-bottom: 1px solid $closeButton;
    @include border-radius(0);
    @include ForMarginPadding(padding, rem(10), rem(20), rem(10), rem(0));
  }
}

.searchClose {
  position: absolute;
  top: rem(42);
  @include RightToLeft(rem(20));
  background: $lightGrey;
  border: none;
  color: $white;
  width: rem(20);
  height: rem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  @include border-radius(50%);
  @include transitions(0.5s);
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

  i {
    position: relative;
    top: rem(-3.5);

    &:before {
      font-size: rem(6);
    }
  }
}

@include for-desktop() {
  .topSearchBox {
    max-width: 1156px;
    @include ForMarginPadding(margin, auto, auto, auto, auto);
  }

  .searchClose {
    &:hover {
      background: $primary;
      color: $white;
    }
  }
}

@include for-tablet-landscape() {
  .topSearchBox {
    position: initial;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    @include borderRadius(10px);

    .container {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

      .row {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

        [class^="col-"] {
          @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
        }
      }
    }
  }

  .innerSearchBox {
    background-color: $white;
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
    position: relative;

    .search-fields {
      .btn {
        height: rem(47);
        min-width: auto;

        span {
          display: none;
        }

        i {
          &.left-icon {
            @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
          }
        }
      }
    }
  }

  .searchClose {
    display: none;
  }
}

@include for-tablet-portrait() {
  .topSearchBox {
    position: initial;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    @include borderRadius(10px);

    .container {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

      .row {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

        [class^="col-"] {
          @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
        }
      }
    }
  }

  .innerSearchBox {
    background-color: $white;
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
    position: relative;

    .search-fields {
      .btn {
        height: rem(47);
        min-width: auto;

        span {
          display: none;
        }

        i {
          &.left-icon {
            @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
          }
        }
      }
    }
  }

  .searchClose {
    display: none;
  }
}

@include for-phone-landscape() {
  .topSearchBox {
    position: initial;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    @include borderRadius(10px);

    .container {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

      .row {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

        [class^="col-"] {
          @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
        }
      }
    }
  }

  .innerSearchBox {
    background-color: $white;
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
    position: relative;

    .search-fields {
      .btn {
        height: rem(47);
        min-width: auto;

        span {
          display: none;
        }

        i {
          &.left-icon {
            @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
          }
        }
      }
    }
  }

  .searchClose {
    display: none;
  }
}

@include for-phone-portrait() {
  .topSearchBox {
    position: initial;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    @include borderRadius(10px);

    .container {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));

      .row {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

        [class^="col-"] {
          @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
        }
      }
    }
  }

  .innerSearchBox {
    background-color: $white;
    @include ForMarginPadding(padding, rem(20), rem(20), rem(20), rem(20));
    position: relative;

    .search-fields {
      .btn {
        height: rem(47);
        min-width: auto;

        span {
          display: none;
        }

        i {
          &.left-icon {
            @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
          }
        }
      }
    }
  }

  .searchClose {
    display: none;
  }
}
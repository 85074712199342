.menu-right {
  @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
  @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  display: flex;

  >li {
    list-style: none;
    display: block;
    @include ForMarginPadding(margin, rem(0), rem(15), rem(0), rem(0));

    &:hover {
      color: $primary;
    }

    >div>a {
      font-weight: 400;
      font-size: rem(14);
      @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(20));
      line-height: 1;
      position: relative;
      color: $white;
      text-decoration: none;
      display: block;

      i {
        @include transitions(0.5s);
        position: absolute;
        @include leftToRight(0);
        top: rem(-2);

        &:before {
          font-size: rem(6);
        }
      }

      span {
        display: block;
      }
    }

    &:last-child {
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    }
  }
}

.banner-card {
  width: 100%;
  height: 100%;
  position: absolute;
  @include leftToRight(0);
  top: 0;
  @include borderRadius(4px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  &:before {
    position: absolute;
    @include leftToRight(0);
    top: 0;
    width: 60%;
    height: 100%;
    background: rgba($black, 0.3);
    backdrop-filter: blur(3px);
    content: '';
  }
}

.banner-card-content {
  display: flex;
  flex-direction: column;
  @include ForMarginPadding(padding, rem(15), rem(20), rem(15), rem(20));
  // width: 60%;
  height: 100%;
  position: relative;

  h3 {
    font-size: rem(22);
  }

  h3,
  p {
    color: $white;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
  }

  .btn {
    display: block;
    @include ForMarginPadding(margin, auto, rem(0), rem(0), rem(0));
  }
}

.megamenu-banner-wrapper {
  display: flex;
}


.dropUpArrow {
  display: none !important;
}

.dropdown-wrapper {
  opacity: 0;
  visibility: hidden;
}

@include for-desktop() {
  .menu-right {
    >li {
      >div>a {
        &:hover {
          i {
            top: rem(3);
            transform: rotate(180deg);
          }
        }
      }

      &.active {
        .dropUpArrow {
          opacity: 1;
          visibility: visible;
          transform: translateY(0px);
        }
      }
    }
  }

  .menuList {
    ul {
      li {

        &.active,
        &:hover {
          >ul {
            opacity: 1;
            visibility: visible;
            transform: translateX(rem(0));
          }

          >a {
            color: $primary
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    @include leftToRight(0);
    @include RightToLeft(0);
    // top: 100%;
    @include transitions(0.5s);
    width: rem(1156);
    @include ForMarginPadding(padding, rem(0), rem(0), rem(0), rem(0));
    @include ForMarginPadding(margin, auto, auto, auto, auto);
    transform: translateY(20px);

    .dropdown {
      top: 27px;
      width: fit-content;
      @include ForMarginPadding(padding, rem(26), rem(20), rem(26), rem(20));

      &.backdrop {
        box-shadow: 0px 10px 15px rgb(0 0 0 / 35%);
        background: rgba($white, 0.9);
        @include borderRadius(10px);
      }
    }

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      opacity: 0;
      visibility: hidden;
      transform: translateY(0px);
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      opacity: 1;
      visibility: visible;
      transform: translateY(0px);
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      opacity: 0;
      visibility: hidden;
      transform: translateY(20px);
    }
  }

  .dropUpArrow {
    position: absolute;
    @include leftToRight(0);
    @include RightToLeft(0);
    @include ForMarginPadding(margin, auto, auto, auto, auto);
    top: calc(100% + 7px);
    width: 0;
    height: 0;
    border-width: 0 11.5px 16px 11.5px;
    border-color: transparent transparent rgba($white, 0.9) transparent;
    border-style: solid;
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transform: translateY(20px);
    @include transitions(0.5s);
  }
}

@include for-tablet-landscape() {
  .menu-right {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    >li {
      display: block;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      >div>a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    position: absolute;
    @include RightToLeft(-100%);
    @include transitions(0.5s);
    top: 0;
    width: 100%;
    height: 100%;
    background: $white;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 2;

    .dropdown {
      width: 100%;
      height: 100%;
    }

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
      opacity: 0;
      visibility: hidden;
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0);
      opacity: 1;
      visibility: visible;
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
      opacity: 0;
      visibility: hidden;
    }
  }

  .megamenu-banner-wrapper {
    flex-direction: column-reverse;
  }


}

@include for-tablet-portrait() {
  .menu-right {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    >li {
      display: block;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      >div>a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    position: absolute;
    @include RightToLeft(-100%);
    @include transitions(0.5s);
    top: 0;
    width: 100%;
    height: 100%;
    background: $white;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 2;

    .dropdown {
      width: 100%;
      height: 100%;
    }

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
      opacity: 0;
      visibility: hidden;
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0);
      opacity: 1;
      visibility: visible;
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
      opacity: 0;
      visibility: hidden;
    }
  }

  .megamenu-banner-wrapper {
    flex-direction: column-reverse;
  }

}

@include for-phone-landscape() {
  .menu-right {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    >li {
      display: block;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      >a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    position: absolute;
    @include RightToLeft(-100%);
    @include transitions(0.5s);
    top: 0;
    width: 100%;
    height: 100%;
    background: $white;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 2;

    .dropdown {
      width: 100%;
      height: 100%;
    }

    &.active {
      @include RightToLeft(0);
      opacity: 1;
      visibility: visible;
    }
  }

  .megamenu-banner-wrapper {
    flex-direction: column-reverse;
  }

}

@include for-phone-portrait() {
  .menu-right {
    display: block;
    border-bottom: solid 1px $grey;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(20), rem(0));
    @include ForMarginPadding(margin, rem(0), rem(0), rem(20), rem(0));

    >li {
      display: block;
      @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));

      >div>a {
        @include ForMarginPadding(padding, rem(10), rem(0), rem(10), rem(0));
        color: $secondary;
        font-size: rem(16);
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        align-items: center;

        i {
          position: initial;

          &:before {
            content: '\e901';
            font-size: rem(14);
          }
        }
      }
    }
  }

  .dropdown-wrapper {
    position: absolute;
    @include transitions(0.5s);
    top: 0;
    width: 100%;
    height: 100%;
    background: $white;
    @include ForMarginPadding(padding, rem(86), rem(20), rem(20), rem(20));
    z-index: 2;
    @include RightToLeft(-100%);

    .dropdown {
      width: 100%;
      height: 100%;
    }

    &.active-appear-done,
    &.active-appear-active,
    &.active-appear {
      @include RightToLeft(-100%);
      opacity: 0;
      visibility: hidden;
    }

    &.active-enter,
    &.active-enter-active,
    &.active-enter-done {
      @include RightToLeft(0);
      opacity: 1;
      visibility: visible;
    }

    &.active-exit,
    &.active-exit-active,
    &.active-exit-done {
      @include RightToLeft(-100%);
      opacity: 0;
      visibility: hidden;
    }
  }

  .megamenu-banner-wrapper {
    flex-direction: column-reverse;
  }
}
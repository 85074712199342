.our-values-section-wrapper {
  width: 100%;
  // height: rem(400);
  @include ForMarginPadding(padding, rem(100), rem(10), rem(100), rem(10));
  background: $gray_primary;
  background: linear-gradient(
      180deg,
      rgba(24, 25, 23, 0.568) 57.81%,
      #191817 100%
    ),
    linear-gradient(
      360deg,
      rgba(24, 25, 23, 0) 67.84%,
      rgba(24, 25, 23, 0.438) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  background-blend-mode: normal, multiply, normal, normal;
  color: $white;
  overflow: hidden;
  position: relative;
  @include transitions(0.5s);

  &:before {
    position: absolute;
    @include leftToRight(0);
    top: 0;
    // width: rem(586);
    width: 100%;
    height: 100%;
    background-image: url("/images/our-values.jpg");
    background-repeat: repeat-x;
    // background-position: -50% 0;
    background-position: center;
    background-size: cover;
    content: "";
    z-index: -1;
  }
  .ourValuesHeading {
    color: $primary-wattone;
    text-align: center;
    font-size: $h1-font-size;
    @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
    span {
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        @include ForMarginPadding(margin, rem(0), rem(0), rem(0), rem(0));
      }
      p:first-child {
        color: $white;
        font-weight: normal;
        @include ForMarginPadding(margin, rem(0), rem(10), rem(0), rem(0));
      }
    }
  }
  .ourValuesSlugan {
    text-transform: uppercase;
    color: $white;
    font-size: 10px;
    letter-spacing: 5px;
    text-align: center;
    @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
  }

  .ourValueSlider {
    width: 100%;
    .scrolling-words-box {
      div {
        @include ForMarginPadding(margin, rem(20), rem(0), rem(0), rem(0));
        .ourValuesSlide {
          color: $primary-wattone;
          text-align: center;
          font-size: $h1-font-size;
        }
      }
      span {
        @include ForMarginPadding(margin, rem(40), rem(0), rem(0), rem(0));
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .ourValuesDescription {
          color: $secondary;
          text-align: center;
          font-size: $h4-font-size;
        }
      }
    }
  }
}

@include for-tablet-landscape() {
  .our-values-section-wrapper {
    .ourValuesHeading {
      font-size: $h2-font-size;
    }
    .ourValuesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourValueSlider {
      .scrolling-words-box {
        div {
          .ourValuesSlide {
            color: $primary-wattone;
            text-align: center;
            font-size: $h2-font-size;
          }
        }
      }
    }
  }
}

@include for-tablet-portrait() {
  .our-values-section-wrapper {
    .ourValuesHeading {
      font-size: $h2-font-size;
    }
    .ourValuesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourValueSlider {
      .scrolling-words-box {
        div {
          .ourValuesSlide {
            color: $primary-wattone;
            text-align: center;
            font-size: $h2-font-size;
          }
        }
      }
    }
  }
}

@include for-phone-landscape() {
  .our-values-section-wrapper {
    .ourValuesHeading {
      font-size: $h3-font-size;
    }
    .ourValuesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourValueSlider {
      .scrolling-words-box {
        div {
          .ourValuesSlide {
            color: $primary-wattone;
            text-align: center;
            font-size: $h3-font-size;
          }
        }
      }
    }
  }
}

@include for-phone-portrait() {
  .our-values-section-wrapper {
    .ourValuesHeading {
      font-size: $h3-font-size;
    }
    .ourValuesSlugan {
      @include ForMarginPadding(padding, rem(0), rem(0), rem(30), rem(0));
    }
    .ourValueSlider {
      .scrolling-words-box {
        div {
          .ourValuesSlide {
            color: $primary-wattone;
            text-align: center;
            font-size: $h3-font-size;
          }
        }
      }
    }
  }
}
